/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 03/03/2025 - 22:16:43
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 03/03/2025
    * - Author          : 
    * - Modification    : 
**/
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { MdDeleteOutline } from "react-icons/md";
import IconWrapper from "../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';

// Modified interface to make it more flexible with optional properties
interface ReportCardProps {
  data: {
    date: string;
    id: string;
    projectName: string;
    userId: string;
    totalCalcualetdReports: number; // Required by interface
    calculated?: boolean;
    status?: string;
    timestamp?: number;
    [key: string]: any; // Allow any additional properties
  };
  onStatusChange?: any;
}

const ReportCard: React.FC<ReportCardProps> = ({ data, onStatusChange }) => {
  const { t } = useTranslation(['reportCard', 'myReports']);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState([{}]);
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");

  const handleOpenReport = async (id: string) => {
    setOpen(true);
    const q = query(
      collection(db, "paidReport"),
      where("userId", "==", data.userId),
      where("projectId", "==", id)
    );
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    
    // Sort the data by date (newest first by default)
    sortModalData(newData, sortOrder);
  };

  // Function to sort modal data
  const sortModalData = (data: any[], order: "newest" | "oldest") => {
    const sortedData = [...data].sort((a: any, b: any) => {
      if (!a.date || !b.date) return 0;
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return order === "newest" ? dateB - dateA : dateA - dateB;
    });
    
    setModalData(sortedData);
  };

  // Handle sort toggle
  const toggleSort = () => {
    const newOrder = sortOrder === "newest" ? "oldest" : "newest";
    setSortOrder(newOrder);
    sortModalData(modalData, newOrder);
  };

  const deleteReportData = async() => {
    if (data.userId) {
      const q = query(
        collection(db, "paidReport"),
        where("userId", "==", data.userId),
        where("projectId", "==", data.id)
      );

      const querySnapshot = await getDocs(q);
      const deletePromises = querySnapshot.docs.map((document) =>
        deleteDoc(doc(db, "paidReport", document.id))
      );

      // Await all delete operations to finish
      await Promise.all(deletePromises).then(async () => {
        const userDocRef = doc(db, "projects", data.id);
        await updateDoc(userDocRef, {
          calculated: false,
        });
        onStatusChange();
      });
    }
  };

  return (
    <React.Fragment>
      <div className="reportsCard px-3">
        <div className="d-flex m-0 justify-content-between align-items-center py-3">
          <p className="reportID">
            <b>{t('reportCard:projectDetails.projectName')}</b> : {data.projectName}
          </p>
          <IconWrapper 
            Icon={MdDeleteOutline}
            cursor={"pointer"}
            size={18}
            color="red"
            onClick={deleteReportData}
          />
        </div>
        <hr className="m-0" />
        <p className="mt-3 m-0 reportCardText">{t('reportCard:projectDetails.projectId')} : {data.id}</p>
        <p className="m-0 reportCardText">{t('reportCard:projectDetails.dateTime')} : {data.date}</p>
        <p className="m-0 reportCardText">
          {t('reportCard:projectDetails.calculatedReports')} : {data.totalCalcualetdReports || 0}
        </p>
        <div className="w-100 d-flex justify-content-center mt-3 align-items-center">
          <Button
            variant="none"
            className="reportsOpenProject m-0 p-0"
            onClick={() => handleOpenReport(data.id)}
          >
            {t('reportCard:buttons.openReports')}
          </Button>
        </div>
      </div>
      <Modal
        show={open}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="reportModal"
      >
        {modalData.length > 0 && (
          <div>
            <Modal.Header className="d-flex justify-content-between align-items-center border-0">
              <div>
                <Modal.Title
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  id="contained-modal-title-vcenter"
                >
                  {t('reportCard:modal.title')}
                </Modal.Title>
                <div 
                  className="mt-2" 
                  style={{ cursor: "pointer", fontSize: "14px", color: "#666" }}
                  onClick={toggleSort}
                >
                  <span>{t('reportCard:modal.sort')}: {sortOrder === "newest" ? t('myReports:newestFirst') : t('myReports:oldestFirst')}</span>
                </div>
              </div>
              <IconWrapper 
                Icon={RiCloseFill}
                style={{ cursor: "pointer" }}
                size={22}
                onClick={() => setOpen(false)}
              />
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0" style={{ top: "0px" }}>
              {modalData.map((value: any) => {
                return (
                  <div key={value.id} className="reportsCard mb-3 px-3">
                    <div className="d-flex m-0 justify-content-between align-items-center pt-3">
                      <p className="reportID">
                        <b>{t('reportCard:modal.projectInfo.name')}</b> : {value.id}
                      </p>
                      <p className="reportID">
                        <b>{t('reportCard:modal.projectInfo.status')}</b> : {value.paymentStatus ? t('reportCard:modal.reportInfo.paid') : t('reportCard:modal.reportInfo.free')}
                      </p>
                    </div>
                    <hr className="m-0" />
                    <p className="mt-3 m-0 reportCardText">{value.date}</p>
                    <p className="m-0 reportCardText">
                      {value.tiltedPVArea + value.verticalPVArea} {t('reportCard:modal.reportInfo.pvAreas')}
                    </p>
                    <p className="m-0 reportCardText">
                      {value.detectionPoints} {t('reportCard:modal.reportInfo.detectionPoints')}
                    </p>
                    <div className="d-flex justify-content-between align-items-center mt-3 gap-2">
                      <Button
                        variant="none"
                        className="reportsOpenProject p-0 m-0"
                        onClick={() => {
                          if (value.paymentStatus) {
                            navigate("/paidproject", {
                              state: value.id,
                            });
                          } else {
                            navigate("/unpaidproject", {
                              state: { projectId: value.id },
                            });
                          }
                        }}
                      >
                        {t('reportCard:buttons.openReport')}
                      </Button>
                      <a
                        href={
                          value.paymentStatus
                            ? value?.fileUrl?.paidReportUrl
                            : value?.fileUrl?.reportUrl
                        }
                        download={true}
                      >
                        <Button
                          variant="none"
                          className="reportsDownloadProject p-0 m-0"
                        >
                          {t('reportCard:buttons.downloadReport')}
                        </Button>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Modal.Body>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ReportCard;
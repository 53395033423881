/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/03/2025 - 17:06:03
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/03/2025
    * - Author          : 
    * - Modification    : 
**/

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  Button,
  Image,
  Form,
  OverlayTrigger,
  Tooltip,
  Accordion,
} from "react-bootstrap";
import {
  addSlopeArea,
  addTiltedPolygon,
  deletePolygon,
  deletePolygonHeights,
  deleteTiltedAzimuthValue,
  deleteTiltedSwap,
  deleteTiltedchecked,
  removeSlopeArea,
  removeTiltedAverageElevation,
  setTilAzimuthCheck,
  setTilEdgeCheck,
  setTilElevationCheck,
  setTiltedAzimutyhValue,
  setTiltedLowerHeight,
  setTiltedSwap,
  setTiltedUpperHeight,
  settiltedAverageElevation,
} from "../../../../store/reducers/bmapSlice";
import { api } from "../../../../api/data";
import axios from "axios";
import { toast } from "react-toastify";
import AddTiledPvArea from "../../../../images/Add Tilted PV Area Vector.png";
// import icons
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { putData } from '../../../../utils/axios';
import IconWrapper from "../../../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';

interface AzimuthResponse {
  aziumth: number;
}

const TiltedPvAreaPage: React.FC<{ projectName: string }> = ({
  projectName,
}) => {
  const selectedArea = useSelector(
    (state: RootState) => state.bmap.selectedArea
  );
  const polygons = useSelector((state: RootState) => state.bmap.tiltedPolygons);
  const tiltedAzimuth = useSelector(
    (state: RootState) => state.bmap.tiltedAzimuthValue
  );
  const tiltedLowerHeight = useSelector(
    (state: RootState) => state.bmap.tiltedlowerHeights
  );
  const tiltedUpperHeight = useSelector(
    (state: RootState) => state.bmap.tiltedupperHeights
  );
  const swapChecked = useSelector((state: RootState) => state.bmap.tiltedSwap);
  const slopeArea = useSelector((state: RootState) => state.bmap.slopeArea);
  const averageElevation = useSelector(
    (state: RootState) => state.bmap.tiltedAverageElevation
  );
  const checkedElevation = useSelector(
    (state: RootState) => state.bmap.tiltedElevationChecked
  );
  const checkedAzimuth = useSelector(
    (state: RootState) => state.bmap.tiltedAzimuthChecked
  );
  const checkedIndex = useSelector(
    (state: RootState) => state.bmap.tiltedEdgeChecked
  );
  const [polygonIndexLength, setPolygonIndexLength] = useState<number>(0);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const dispatch = useDispatch();
  const [length, setLength] = useState<number[]>([]);
  const { t } = useTranslation(['tiltedPvArea', 'common']);
  const handleToggle = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const [elevationFetchStatus, setElevationFetchStatus] = useState<{[index: number]: 'pending' | 'fetching' | 'complete' | 'error'}>({});
  const [pointElevations, setPointElevations] = useState<number[][]>(
    Array(polygons.length).fill([])
  );

  // const [checkedIndex, setCheckedIndex] = useState<(string | null)[]>([]);
  const [selectedValueIndex, setSelectedValueIndex] = useState<number>(0);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState<boolean[]>([]);
  // const [checkedElevation, setCheckedElevation] = useState<boolean[]>([]);
  // const [checkedAzimuth, setCheckedAzimuth] = useState<boolean[]>([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (value: string, index: number) => {
    // console.log("object", checkedIndex, value);
    if (checkedIndex[index] === value && checkedIndex[index] !== undefined) {
      // console.log("object");
      dispatch(setTilEdgeCheck({ index, check: null }));
    } else {
      dispatch(setTilEdgeCheck({ index, check: value }));
    }
  };
  
  //  --------------To set elevation when uer enter new polygon------------------------
  const handleDonePolygon = async () => {
    // Check if all polygons have their elevations fetched
    const allElevationsFetched = polygons.every((_, index) => 
      elevationFetchStatus[index] === 'complete'
    );
    
    if (!allElevationsFetched) {
      console.log("Not all polygon elevations are fetched yet, triggering fetch for missing ones");
      
      // Attempt to fetch elevations for any missing polygons
      polygons.forEach((_, index) => {
        if (!elevationFetchStatus[index] || elevationFetchStatus[index] === 'pending' || elevationFetchStatus[index] === 'error') {
          fetchElevations(index);
        }
      });
      
      return;
    }
    
    console.log("All polygon elevations fetched, processing data for localStorage");
    
    // Create a new array to hold the azimuth data for all polygons
    const azimuthDataArray: {
      azimuth: string;
      polygonData: { [x: string]: any };
    }[] = [];
  
    // Process each polygon
    for (let i = 0; i < polygons.length; i++) {
      // Skip this polygon if it doesn't have 4 points of elevation data
      if (!pointElevations[i] || pointElevations[i].length !== 4) {
        console.log(`Skipping polygon ${i}: Missing elevation data`, pointElevations[i]);
        continue;
      }
      
      // Skip if any of the elevations is undefined
      const hasUndefinedElevation = pointElevations[i].some(
        (elevation) => elevation === undefined || elevation === null
      );
      
      if (hasUndefinedElevation) {
        console.log(`Skipping polygon ${i}: Has undefined elevation values`);
        continue;
      }
  
      // Skip if there's no height data but auto-azimuth is enabled
      if (
        tiltedLowerHeight[i] === null &&
        tiltedUpperHeight[i] === null &&
        checkedAzimuth[i] === true
      ) {
        dispatch(
          setTiltedAzimutyhValue({
            index: i,
            azimuth: null,
          })
        );
        continue;
      }
      
      // Process polygon if it has all required data
      if (
        polygons.length >= 1 &&
        pointElevations[i]?.length === 4 &&
        tiltedLowerHeight[i] != null &&
        tiltedUpperHeight[i] != null &&
        checkedAzimuth[i] === true
      ) {
        const polygon = polygons[i];
        const currentElevations = pointElevations[i];
  
        const data: { [key: string]: any } = {
          area_type: "tilted"
        };
  
        // Prepare the data for each polygon point
        const points = await Promise.all(
          polygon?.map(async (point: { lat: any; lng: any }, j: number) => {
            let tag = j < 2 ? "upper_edge" : "lower_edge";
            const elevation = currentElevations[j];
  
            return {
              lat: point.lat,
              lon: point.lng,
              elevation: elevation,
              offset: 0,
              tag,
            };
          })
        );
  
        points.forEach((point, j) => {
          data[`point${j + 1}`] = point;
        });
        
        const allPolygonData = { ...data };
        
        try {
          console.log(`Fetching azimuth for polygon ${i} with data:`, allPolygonData);
          
          const response: AzimuthResponse = await putData<AzimuthResponse, typeof allPolygonData>(
            '/azimuth/',
            allPolygonData,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
      
          const result = response.aziumth;
          console.log(`Got azimuth for polygon ${i}:`, result);
          
          if (checkedAzimuth[i]) {
            dispatch(
              setTiltedAzimutyhValue({
                index: i,
                azimuth: Number(result).toFixed(1),
              })
            );
          }
      
          const azimuth = JSON.stringify(result);
          azimuthDataArray.push({ azimuth, polygonData: allPolygonData });
        } catch (err) {
          console.error(`Error fetching azimuth data for polygon ${i}:`, err);
        }
      }
    }
    
    // Save all azimuth data to localStorage if we have any
    if (azimuthDataArray.length > 0) {
      console.log("Saving azimuth data array:", azimuthDataArray);
      localStorage.setItem('AzimuthDataArray', JSON.stringify(azimuthDataArray));
    }
  };
  
  // Update the useEffect that detects new polygons to mark them for elevation fetching
  useEffect(() => {
    // When a new polygon is added, mark it as needing elevation fetching
    const lastIndex = polygons.length - 1;
    if (lastIndex >= 0 && !elevationFetchStatus[lastIndex]) {
      console.log(`New polygon detected at index ${lastIndex}, marking for elevation fetch`);
      setElevationFetchStatus(prev => ({
        ...prev,
        [lastIndex]: 'pending'
      }));
    }
  }, [polygons.length]);
  
  // Handle the elevation fetching in a separate useEffect
  useEffect(() => {
    // Check for any polygons needing elevation fetching
    const pendingPolygons = Object.entries(elevationFetchStatus)
      .filter(([_, status]) => status === 'pending')
      .map(([index]) => parseInt(index));
    
    if (pendingPolygons.length > 0) {
      console.log(`Found ${pendingPolygons.length} polygons needing elevation fetch:`, pendingPolygons);
      
      // Process each pending polygon one at a time
      pendingPolygons.forEach(index => {
        setTimeout(() => {
          fetchElevations(index);
        }, 300 * index); // Stagger the requests slightly
      });
    }
  }, [elevationFetchStatus]);
  
  // Modify the useEffect that triggers handleDonePolygon
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
  
    const delayedFetch = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleDonePolygon();
      }, 500);
    };
  
    // Only trigger if we have some polygon data and any of these dependencies changed
    if (polygons.length > 0) {
      delayedFetch();
    }
  
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    pointElevations, 
    checkedAzimuth, 
    tiltedLowerHeight, 
    tiltedUpperHeight,
    elevationFetchStatus // Add this as a dependency
  ]);
  
  // Modified to initialize elevation fetching for existing polygons when component mounts
  useEffect(() => {
    // Initialize elevation fetch status for all existing polygons
    if (polygons.length > 0) {
      const initialStatus: {[index: number]: 'pending' | 'fetching' | 'complete' | 'error'} = {};
      
      polygons.forEach((_, index) => {
        // If a polygon already has elevation data, mark it as complete
        if (pointElevations[index]?.length === 4 && averageElevation[index] !== undefined) {
          initialStatus[index] = 'complete';
        } else {
          initialStatus[index] = 'pending';
        }
      });
      
      setElevationFetchStatus(initialStatus);
      console.log("Initialized elevation fetch status:", initialStatus);
    }
  }, []);

  useEffect(() => {
    const polygonLength = polygons.length - 1;
    dispatch(setTiltedSwap({ index: polygonLength, checked: false }));
    if (checkedElevation[polygonLength] === undefined) {
      dispatch(setTilElevationCheck({ index: polygonLength, check: true }));
    }
    if (checkedAzimuth[polygonLength] === undefined) {
      dispatch(setTilAzimuthCheck({ index: polygonLength, check: true }));
    }
    if (checkedIndex[polygonLength] === undefined) {
      dispatch(setTilEdgeCheck({ index: polygonLength, check: "PVangle" }));
    }
  }, [polygons.length]);




const fetchElevations = async (polygonIndex: number) => {
  // Don't process if this polygon is already being fetched or is complete
  if (elevationFetchStatus[polygonIndex] === 'fetching' || 
      elevationFetchStatus[polygonIndex] === 'complete') {
    return;
  }
  
  // Mark as fetching
  setElevationFetchStatus(prev => ({
    ...prev,
    [polygonIndex]: 'fetching'
  }));
  
  // Check if the polygon has 4 points (complete polygon)
  if (polygons[polygonIndex]?.length === 4) {
    try {
      const elevations: number[] = [];
      
      // Fetch elevations for all points in the polygon
      for (let i = 0; i < polygons[polygonIndex].length; i++) {
        const point = polygons[polygonIndex][i];
        const response = await api.getElevation(point.lat, point.lng);
        const elevation = response.data[0]?.results[0]?.elevation ?? null;
        
        // Skip processing if elevation is null - we need all 4 elevations
        if (elevation === null) {
          console.error(`Null elevation for point ${i} in polygon ${polygonIndex}`);
          
          // Mark as error
          setElevationFetchStatus(prev => ({
            ...prev,
            [polygonIndex]: 'error'
          }));
          
          return; // Exit early if any elevation is null
        }
        
        elevations.push(elevation);
      }
      
      // Only proceed if we have all 4 elevations
      if (elevations.length === 4) {
        console.log(`Got all elevations for polygon ${polygonIndex}:`, elevations);
        
        // Update the pointElevations state
        setPointElevations((prevElevations) => {
          const newElevations = [...prevElevations];
          newElevations[polygonIndex] = elevations;
          return newElevations;
        });

        // Calculate the average elevation
        const totalElevation = elevations.reduce(
          (acc, elevation) => acc + elevation,
          0
        );
        const AverageElevation = totalElevation / elevations.length;
        
        if (checkedElevation[polygonIndex]) {
          dispatch(
            settiltedAverageElevation({
              index: polygonIndex,
              elevation: AverageElevation.toFixed(1),
            })
          );
        }
        
        // Mark as complete
        setElevationFetchStatus(prev => ({
          ...prev,
          [polygonIndex]: 'complete'
        }));
      }
    } catch (error) {
      console.error(`Error fetching elevations for polygon ${polygonIndex}:`, error);
      toast.error("Error fetching elevations");
      
      // Mark as error
      setElevationFetchStatus(prev => ({
        ...prev,
        [polygonIndex]: 'error'
      }));
      
      dispatch(
        settiltedAverageElevation({
          index: polygonIndex,
          elevation: null,
        })
      );
    }
  }
};

const triggerAzimuthUpdate = (index: number) => {
  // Skip if auto-calculate is not enabled
  if (!checkedAzimuth[index]) return;
  
  // Skip if we don't have complete data
  if (!polygons[index] || 
      polygons[index].length !== 4 || 
      !pointElevations[index] || 
      pointElevations[index].length !== 4 ||
      tiltedLowerHeight[index] === null || 
      tiltedUpperHeight[index] === null) {
    return;
  }
  
  // Call handleDonePolygon (which already has the API call logic)
  handleDonePolygon();
};

// 2. Modify the handler for the azimuth auto-calculate checkbox
const handleAzimuthCheckChange = (index: number, checked: boolean) => {
  dispatch(
    setTilAzimuthCheck({
      index: index,
      check: checked,
    })
  );
  
  // If turning on auto-calculate, immediately trigger an update
  if (checked) {
    // Short timeout to ensure state is updated before triggering
    setTimeout(() => triggerAzimuthUpdate(index), 50);
  }
};

// 3. Add a focused useEffect for azimuth updates when data changes
useEffect(() => {
  // Skip if no polygons
  if (polygons.length === 0) return;
  
  // For each polygon with auto-calculate on, check if it needs an update
  polygons.forEach((_, index) => {
    if (checkedAzimuth[index]) {
      // Use a debounced trigger to avoid too many API calls
      const timeoutId = setTimeout(() => {
        triggerAzimuthUpdate(index);
      }, 300); // Small debounce time
      
      return () => clearTimeout(timeoutId);
    }
  });
}, [
  // Dependencies that should trigger azimuth recalculation
  JSON.stringify(polygons),
  JSON.stringify(pointElevations),
  JSON.stringify(tiltedLowerHeight),
  JSON.stringify(tiltedUpperHeight)
]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const delayedFetch = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleDonePolygon();
      }, 300); // 1 second delay
    };
    if (pointElevations[pointElevations.length - 1] != undefined) {
      delayedFetch(); // Initial call on component mount
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [pointElevations, checkedAzimuth, tiltedLowerHeight, tiltedUpperHeight]);

  // To delete the polygon and all the data to the related polygon that store in localStorage or redux state
  const handleDeletePolygon = (index: number) => {
    dispatch(deletePolygon(index));
    dispatch(deletePolygonHeights({ index }));
    dispatch(deleteTiltedSwap({ index }));
    dispatch(removeTiltedAverageElevation({ index }));
    dispatch(deleteTiltedAzimuthValue({ index }));
    // const azimuthDataArray = localStorage.getItem("AzimuthDataArray");
    // if (azimuthDataArray) {
    //   const parsedData = JSON.parse(azimuthDataArray);
    //   parsedData.splice(index, 1);
    //   localStorage.setItem("AzimuthDataArray", JSON.stringify(parsedData));
    // }
    dispatch(deleteTiltedchecked({ index }));
    dispatch(removeSlopeArea(index));
    pointElevations.splice(index, 1);
  };

  // Function to calculate the upper and lower distance
  useEffect(() => {
    const calculateUpperLowerEdgeDistance = async () => {
      const newLength: number[] = [];

      polygons.forEach((coordSet: string | any[]) => {
        if (coordSet.length === 4) {
          // Calculate midpoint of upper edge
          const upperEdgeMidpoint = {
            lat: (coordSet[0].lat + coordSet[1].lat) / 2,
            lng: (coordSet[0].lng + coordSet[1].lng) / 2,
          };
          // Calculate midpoint of lower edge
          const lowerEdgeMidpoint = {
            lat: (coordSet[2].lat + coordSet[3].lat) / 2,
            lng: (coordSet[2].lng + coordSet[3].lng) / 2,
          };

          // Calculate distance between midpoints
          const length =
            google?.maps?.geometry?.spherical.computeDistanceBetween(
              new google.maps.LatLng(
                lowerEdgeMidpoint.lat,
                lowerEdgeMidpoint.lng
              ),
              new google.maps.LatLng(
                upperEdgeMidpoint.lat,
                upperEdgeMidpoint.lng
              )
            );
          newLength.push(length);
        }
      });

      setLength(newLength);
    };

    calculateUpperLowerEdgeDistance();
  }, [polygons]);

  const calculateSlope = (index: number) => {
    if (length[index]) {
      const heightDifference =
        Number(tiltedUpperHeight[index]) - Number(tiltedLowerHeight[index]);
      const ratio = heightDifference / length[index];
      const angleRad = Math.atan(ratio);
      const angle = Number((angleRad * (180 / Math.PI)).toFixed(1));
      dispatch(addSlopeArea({ index, value: angle }));
      return angle;
    }
  };
  const calculateUpperEdge = (index: number) => {
    const angleInDegrees = slopeArea[index];
    const angleInRadians = angleInDegrees * (Math.PI / 180);
    const tanValue = Math.tan(angleInRadians) * length[index];
    const upperHeight = Number(tiltedLowerHeight[index]) + Number(tanValue);
    const floatValue = Number(upperHeight.toFixed(1));
    dispatch(setTiltedUpperHeight({ index, floatValue }));
    return floatValue;
  };

  const calculateLowerEdge = (index: number) => {
    const angleInDegrees = slopeArea[index];
    const angleInRadians = angleInDegrees * (Math.PI / 180);
    const tanValue = Math.tan(angleInRadians) * length[index];
    const lowerFloatValue = Number(tiltedUpperHeight[index] - tanValue).toFixed(
      1
    );
    dispatch(setTiltedLowerHeight({ index, lowerFloatValue }));
    return lowerFloatValue;
  };

  useEffect(() => {
    if (checkedIndex[selectedValueIndex] === "PVangle") {
      calculateSlope(selectedValueIndex);
    } else if (checkedIndex[selectedValueIndex] === "lowerEdge") {
      calculateLowerEdge(selectedValueIndex);
    } else if (checkedIndex[selectedValueIndex] === "upperEdge") {
      calculateUpperEdge(selectedValueIndex);
    }
  }, [
    tiltedUpperHeight[selectedValueIndex],
    tiltedLowerHeight[selectedValueIndex],
    slopeArea[selectedValueIndex],
    length[selectedValueIndex],
    checkedIndex[selectedValueIndex],
  ]);

  const toggleDropdown = (index: number) => {
    const newDropdownState = [...isDropdownExpanded];
    newDropdownState[index] = !newDropdownState[index];
    setIsDropdownExpanded(newDropdownState);
  };

  const calculateTheEdgeLength = (
    coord1: { lat: number; lng: number },
    coord2: { lat: number; lng: number }
  ) => {
    const length = google?.maps?.geometry?.spherical.computeDistanceBetween(
      new google.maps.LatLng(coord1?.lat, coord1?.lng),
      new google.maps.LatLng(coord2?.lat, coord2?.lng)
    );
    return length.toFixed(1);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('tiltedPvArea:tooltips.tiltAngle')}
      </small>
    </Tooltip>
  );

  const Azimuthtooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('tiltedPvArea:tooltips.azimuth')}
      </small>
    </Tooltip>
  );


  const groundElevationTooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('tiltedPvArea:tooltips.groundElevation')}
      </small>
    </Tooltip>
  );
  // console.log(tiltedLowerHeight[0], tiltedUpperHeight[0]);
  const oneDigitAfterPoint = (enterValue: string) => {
    let value = enterValue;
    if (value.includes(".")) {
      const parts = value.split(".");
      if (parts[1].length > 1) {
        value = `${parts[0]}.${parts[1].slice(0, 1)}`;
        return value;
      }
    }
    return value;
  };

  useEffect(() => {
    if (polygons.length > 0 && polygons.length >= polygonIndexLength) {
      setIsDropdownExpanded((prev) => [
        ...new Array(polygons.length - 1).fill(false),
        true,
      ]);
      setPolygonIndexLength(polygons.length);
    }
  }, [polygons.length]);
  return (
    <div>
      {polygons.length < 1 &&
        (selectedArea?.value == "tilted" ||
          selectedArea?.value == "tiltedTab") && (
            <div className="tiltedNothingToShow">
            <div>
              <Image
                src={AddTiledPvArea}
                alt="addTiltedPVarea"
                loading="lazy"
              />
              <strong>{t('tiltedPvArea:emptyState.title')}</strong>
              <small>
                {t('tiltedPvArea:emptyState.description.part1')} <span>{t('tiltedPvArea:emptyState.description.rooftop')}</span> {t('tiltedPvArea:emptyState.description.and')}
                <span>{t('tiltedPvArea:emptyState.description.groundMounted')}</span> {t('tiltedPvArea:emptyState.description.part2')}
              </small>
            </div>
          </div>
        )}
      {polygons.length > 0 &&
        (selectedArea?.value == "tilted" ||
          selectedArea?.value == "tiltedTab") && (
          <div style={{ height: "75vh", overflowY: "auto", width: "100%" }}>
            {polygons.map((polygonData, index) => {
              return (
                <div className="tiltedPVarea mb-2" key={`tiltedPoints${index}`}>
                  <Accordion
                    className="w-100"
                    activeKey={isDropdownExpanded[index] ? `${index}` : null}
                  >
                    <Accordion.Item eventKey={`${index}`}>
                      <Accordion.Header onClick={() => toggleDropdown(index)}>
                      <p className="projectName my-auto">
    {" "}
    {`${t('tiltedPvArea:card.prefix')} - ${index + 1}`} &nbsp; |
    <IconWrapper Icon={MdDeleteOutline} size={17}
      color="red"
      className="mb-1"
      onClick={() => handleDeletePolygon(index)}/>
  </p>
  <p className={`collapseBtn my-auto`}>
    {isDropdownExpanded[index] ? (
      <span>
        {t('tiltedPvArea:card.collapse')} <IconWrapper Icon={RxCaretUp} size={20}/>
      </span>
    ) : (
      <span>
        {t('tiltedPvArea:card.expand')} <IconWrapper Icon={RxCaretDown} size={20}/>
      </span>
    )}
  </p>
                      </Accordion.Header>
                      <Accordion.Body className="p-2">
                        {/* <Dropdown.Menu className="w-100 p-3 dropdownMenu"> */}
                        <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">
    {t('tiltedPvArea:sections.areaDimensions')}
  </p>
                          {/* <small>Clear All</small> */}
                        </div>

                        <div className="d-flex justify-content-between mt-1">
                          <div className="coolinput">
                          <label htmlFor="input" className="text">
    {t('tiltedPvArea:fields.upperEdgeLength')}
  </label>
                            <input
                              onChange={() => {}}
                              readOnly
                              type="text"
                              name=" input"
                              className="input disabledInput"
                              value={calculateTheEdgeLength(
                                polygonData[0],
                                polygonData[1]
                              )}
                            />
                          </div>

                          <div className="coolinput">
                          <label htmlFor="input" className="text">
    {t('tiltedPvArea:fields.lowerEdgeLength')}
  </label>
                            <input
                              onChange={() => {}}
                              readOnly
                              type="text"
                              name=" input"
                              className="input disabledInput"
                              value={calculateTheEdgeLength(
                                polygonData[2],
                                polygonData[3]
                              )}
                            />
                          </div>
                        </div>
                        <div className="coolinput mt-3">
                        <label
    htmlFor="input"
    className="text"
    style={{ fontSize: "12px", marginBottom: "7px" }}
  >
    {t('tiltedPvArea:fields.distanceBetweenEdges')}
  </label>
                          <input
                            // style={{width:"185px"}}
                            readOnly
                            type="text"
                            name="input"
                            className="input disabledInput"
                            value={`${length[index]?.toFixed(2)}`}
                          />
                        </div>
                        <p className="m-0 mt-4">
    {t('tiltedPvArea:sections.altitudeParameters')}
  </p>
                        <div className="d-flex justify-content-between mt-1">
                          <div className="coolinput">
                          <label
    style={{ color: "#109F0B" }}
    htmlFor="input"
    className="text"
  >
    {t('tiltedPvArea:fields.upperEdgeHeight')}
  </label>
                            <input
                              type="number"
                              name="input"
                              disabled={
                                checkedIndex[index] === "upperEdge"
                                  ? true
                                  : false
                              }
                              className={
                                checkedIndex[index] === "upperEdge"
                                  ? "disabledInput input"
                                  : "input"
                              }
                              onFocus={() => setSelectedValueIndex(index)}
                              onChange={(e) => {
                                const updatedValue = oneDigitAfterPoint(
                                  e.target.value
                                );
                                if (
                                  Number(updatedValue) <
                                  Number(tiltedLowerHeight[index])
                                ) {
                                  toast.error(t('tiltedPvArea:errors.upperEdgeHeight'));
                                }
                                dispatch(
                                  setTiltedUpperHeight({
                                    index,
                                    floatValue:
                                      updatedValue === "" ? null : updatedValue,
                                  })
                                );
                                // }
                              }}
                              value={
                                tiltedUpperHeight[index] === null
                                  ? " "
                                  : tiltedUpperHeight[index]
                              }
                            />
                            <div className="d-flex align-items-center">
                              <Form.Check
                                id="upperEdge"
                                onClick={() => {
                                  handleCheckboxChange("upperEdge", index);
                                }}
                                checked={
                                  checkedIndex[index] === "upperEdge"
                                    ? true
                                    : false
                                }
                                className="m-0 p-0"
                                style={{ outline: "none", boxShadow: "none" }}
                                aria-label="option 1"
                              />
                              <label
    htmlFor="upperEdge"
    className="ms-2"
    style={{ fontSize: "12px", color: "#858585 " }}
  >
    {t('tiltedPvArea:fields.autoCalculate')}
  </label>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="coolinput">
                            <label
    style={{ color: "#EA0303" }}
    htmlFor="input"
    className="text"
  >
    {t('tiltedPvArea:fields.lowerEdgeHeight')}
  </label>
                              <input
                                type="number"
                                name="input"
                                disabled={
                                  checkedIndex[index] === "lowerEdge"
                                    ? true
                                    : false
                                }
                                onFocus={() => setSelectedValueIndex(index)}
                                className={
                                  checkedIndex[index] === "lowerEdge"
                                    ? "disabledInput input"
                                    : "input"
                                }
                                onChange={(e) => {
                                  const value = oneDigitAfterPoint(
                                    e.target.value
                                  );
                                  if (
                                    Number(tiltedUpperHeight[index]) <
                                    Number(value)
                                  ) {
                                    toast.error(t('tiltedPvArea:errors.elevationFetching'));
                                  }
                                  dispatch(
                                    setTiltedLowerHeight({
                                      index,
                                      lowerFloatValue:
                                        value === "" ? null : value,
                                    })
                                  );
                                }}
                                value={
                                  tiltedLowerHeight[index] === null
                                    ? ""
                                    : tiltedLowerHeight[index]
                                }
                              />
                              <div className="d-flex align-items-center">
                                <Form.Check
                                  id="lowerEdge"
                                  onChange={() => {
                                    handleCheckboxChange("lowerEdge", index);
                                  }}
                                  checked={
                                    checkedIndex[index] === "lowerEdge"
                                      ? true
                                      : false
                                  }
                                  className="shadow-none no-outline outline-0"
                                  style={{
                                    margin: "0px",
                                    lineHeight: "0px",
                                    padding: "0px",
                                  }}
                                  aria-label="option 1"
                                />
                                <label
                                  htmlFor="lowerEdge"
                                  className="ms-2"
                                  style={{
                                    fontSize: "12px",
                                    color: "#858585 ",
                                  }}
                                >
                                  Auto-calculate
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="coolinput">
                          <label
    style={{ color: "#858585" }}
    htmlFor="input"
    className="text"
  >
    {t('tiltedPvArea:fields.groundElevation')}
  </label>
                            <input
                              type="number"
                              name="input "
                              step="0.1"
                              className={
                                checkedElevation[index]
                                  ? "disabledInput input"
                                  : "input"
                              }
                              disabled={checkedElevation[index] ? true : false}
                              onChange={(e) => {
                                const value = oneDigitAfterPoint(
                                  e.target.value
                                );
                                dispatch(
                                  settiltedAverageElevation({
                                    index,
                                    elevation: value === "" ? null : value,
                                  })
                                );
                              }}
                              value={
                                averageElevation[index] === null
                                  ? ""
                                  : averageElevation[index]
                              }
                            />
                          </div>

                          <OverlayTrigger
                            placement="top"
                            overlay={groundElevationTooltip}
                          >
                            <Button variant="none" className="mt-3 p-0">
                            <IconWrapper Icon={IoInformationCircleOutline} size={20}  color="#0984E3"/>
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center ">
                          <Form.Check
                            checked={checkedElevation[index]}
                            onChange={(e) => {
                              dispatch(
                                setTilElevationCheck({
                                  index: index,
                                  check: e.target.checked,
                                })
                              );
                            }}
                          />
                          <label
                            htmlFor="upperEdge"
                            className="ms-2"
                            style={{ fontSize: "12px", color: "#858585 " }}
                          >
                            Auto-calculate
                          </label>
                        </div>
                        <div className="default mt-4">
                          <div className="d-flex justify-content-between align-items-center px-2 pt-2">
                          <p className="m-0 defaultText">
    {swapChecked[index] ? t('tiltedPvArea:fields.swapped') : t('tiltedPvArea:fields.default')}
  </p>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={swapChecked[index]}
                                onChange={(e) => {
                                  const upperEdge1 = polygonData[0];
                                  const upperEdge2 = polygonData[1];
                                  const lowerEdge1 = polygonData[2];
                                  const lowerEdge2 = polygonData[3];
                                  const newPolygonData = [
                                    { ...lowerEdge1 },
                                    { ...lowerEdge2 },
                                    { ...upperEdge1 },
                                    { ...upperEdge2 },
                                  ];

                                  dispatch(
                                    addTiltedPolygon({
                                      index,
                                      tiltedPolygon: newPolygonData,
                                    })
                                  );
                                  dispatch(
                                    setTiltedSwap({
                                      index,
                                      checked: e.target.checked,
                                    })
                                  );
                                }}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                          <strong className="floatingLable">
    {t('tiltedPvArea:fields.swapEdges')}
  </strong>
                        </div>

                        <p className="m-0 mt-4">
    {t('tiltedPvArea:sections.angleParameters')} &nbsp;
  </p>
                        <div>
                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="coolinput">
                              <label htmlFor="input" className="text">
    {t('tiltedPvArea:fields.tiltAngle')}
  </label>
                                <input
                                  onChange={(e) => {
                                    const value = oneDigitAfterPoint(
                                      e.target.value
                                    );
                                    dispatch(
                                      addSlopeArea({
                                        index,
                                        value: value === "" ? null : value,
                                      })
                                    );
                                    // }
                                  }}
                                  type="number"
                                  name="input"
                                  onFocus={() => setSelectedValueIndex(index)}
                                  disabled={
                                    checkedIndex[index] === "PVangle"
                                      ? true
                                      : false
                                  }
                                  className={
                                    checkedIndex[index] === "PVangle"
                                      ? "disabledInput input"
                                      : "input"
                                  }
                                  value={
                                    slopeArea[index] === null
                                      ? ""
                                      : slopeArea[index]
                                  }
                                />
                              </div>
                              <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button variant="none" className="mt-3 p-0">
                            <IconWrapper Icon={IoInformationCircleOutline} size={20}  color="#0984E3"/>
                                </Button>
                              </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center">
                              <Form.Check
                                id="PVangle"
                                checked={
                                  checkedIndex[index] === "PVangle"
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  handleCheckboxChange("PVangle", index);
                                }}
                              />
                              <label
                                htmlFor="PVangle"
                                className="ms-2"
                                style={{ fontSize: "12px", color: "#858585 " }}
                              >
                                Auto-calculate
                              </label>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="coolinput">
                              <label htmlFor="input" className="text">
    {t('tiltedPvArea:fields.azimuth')}
  </label>
                                <input
                                  onChange={(e) => {
                                    const value = oneDigitAfterPoint(
                                      e.target.value
                                    );
                                    dispatch(
                                      setTiltedAzimutyhValue({
                                        index,
                                        azimuth: value === "" ? null : value,
                                      })
                                    );
                                  }}
                                  type="number"
                                  name="input"
                                  disabled={checkedAzimuth[index]}
                                  className={
                                    checkedAzimuth[index]
                                      ? "disabledInput input"
                                      : "input"
                                  }
                                  placeholder={
                                    checkedAzimuth[index] === true
                                      ? "Provide height data first"
                                      : ``
                                  }
                                  value={
                                    tiltedAzimuth[index] != null
                                      ? tiltedAzimuth[index]
                                      : " "
                                  }
                                />
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={Azimuthtooltip}
                              >
                                <Button variant="none" className="mt-3 p-0">
                            <IconWrapper Icon={IoInformationCircleOutline} size={20}  color="#0984E3"/>
                                </Button>
                              </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center">
                            <Form.Check
  checked={checkedAzimuth[index]}
  onChange={(e) => {
    handleAzimuthCheckChange(index, e.target.checked);
  }}
/>
                              <label
                                htmlFor="PVangle"
                                className="ms-2"
                                style={{ fontSize: "12px", color: "#858585 " }}
                              >
                                Auto-calculate
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* </Dropdown.Menu>
                </Dropdown> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default TiltedPvAreaPage;

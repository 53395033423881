/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 07/03/2025 - 01:34:11
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 07/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import MapPageFooter from "./components/MapPageFooter";
import MapPageHeader from "./components/MapPageHeader";
import MapPageRender from "./components/MapPageRender";
import MapPageSidebar from "./components/MapPageSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import images from '../../images/index';
import { Image } from "react-bootstrap";

const MapPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const id = location.state;
  const { t } = useTranslation(['mapPage', 'common']);
  const [clickedPolygonVertices, setClickedPolygonVertices] = useState<
    { lat: number; lng: number }[] | null
  >(null);
  const [polygonNames, setPolygonNames] = useState<string[]>([]);
 
  // Function to update the name of a polygon
  const updatePolygonName = (index: number, newName: string) => {
    setPolygonNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = newName;
      return updatedNames;
    });
  };
  const handleNameChange = (index: number, newName: string) => {
    updatePolygonName(index, newName);
  };
  useEffect(()=>{

    if(!id){
      navigate("/start")
    }
  },[])

  return (
    <>
      {id && (
        <div>
          {/* <Image style={{"marginLeft": "23%","marginTop": "17%","width": "50%"}} className="mb-3" src={images.Server} /> */}
 
          <MapPageHeader />
          <div className="Map-page-content">
            <div className="Map-render">
              <MapPageRender />
            </div>
            <div className="Map-sidebar">
              <MapPageSidebar />
            </div>
          </div>
          {/* <MapPageFooter /> */}
        </div>
      )}
    </>
  );
};
export default MapPage;

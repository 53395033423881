/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 17:45:22
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import Navbar from "../../components/shared/navbar";
import Footer from "../../components/shared/footer";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActiveCard from "../../components/pages/activeCards";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth } from "../../firebase";
import images from '../../images/index';
import { useTranslation } from 'react-i18next';

// Define interface for project data
interface ProjectData {
  id: string;
  date?: string;
  timestamp?: number;
  latestReportDate?: string;
  latestReportTimestamp?: number;
  projectName?: string;
  status?: string;
  userId?: string;
  [key: string]: any; // Allow for additional properties
}

const ActiveProjects: React.FC = () => {
  const { t } = useTranslation(['activeProjects', 'common']);
  const [data, setData] = useState<ProjectData[]>([]);
  const [render, setRender] = useState(true);
  const [searchValue, SetSearchValue] = useState("");
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");
  const [loading, setLoading] = useState(true);

  const user = auth.currentUser?.uid;

  const handleStatusChange = () => {
    setRender((prevRender) => !prevRender);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "newest" ? "oldest" : "newest");
  };

  // To get active projects and search from active projects
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setLoading(true);
        try {
          let q;

          if (searchValue.trim() === "") {
            q = query(
              collection(db, "projects"),
              where("userId", "==", user),
              where("status", "==", "active")
            );
          } else {
            q = query(
              collection(db, "projects"),
              where("userId", "==", user),
              where("status", "==", "active"),
              where("projectName", ">=", searchValue),
              where("projectName", "<=", searchValue + "\uf8ff")
            );
          }

          const querySnapshot = await getDocs(q);
          const projects: ProjectData[] = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            
            // Add timestamp if not present
            if (!data.timestamp && data.date) {
              data.timestamp = new Date(data.date).getTime();
            }
            
            return { ...data, id: doc.id };
          });

          // Step 2: For each project, get all reports and find the latest one
          const projectsWithLatestReports = await Promise.all(
            projects.map(async (project: ProjectData) => {
              try {
                // Get all reports for this project
                const reportsQuery = query(
                  collection(db, "paidReport"),
                  where("userId", "==", user),
                  where("projectId", "==", project.id)
                );
                
                const reportsSnapshot = await getDocs(reportsQuery);
                
                if (!reportsSnapshot.empty) {
                  // Find the report with the most recent date
                  let latestDate = "";
                  let latestTimestamp = 0;
                  
                  reportsSnapshot.docs.forEach(doc => {
                    const reportData = doc.data();
                    const reportDate = reportData.date;
                    
                    if (reportDate) {
                      const reportTimestamp = new Date(reportDate).getTime();
                      
                      if (!latestDate || reportTimestamp > latestTimestamp) {
                        latestDate = reportDate;
                        latestTimestamp = reportTimestamp;
                      }
                    }
                  });
                  
                  // Set the latest report date and timestamp for sorting
                  if (latestDate) {
                    project.latestReportDate = latestDate;
                    project.latestReportTimestamp = latestTimestamp;
                  } else {
                    // Fallback to project date if no reports have dates
                    project.latestReportDate = project.date;
                    project.latestReportTimestamp = project.timestamp || 
                      (project.date ? new Date(project.date).getTime() : 0);
                  }
                } else {
                  // No reports found, use project date
                  project.latestReportDate = project.date;
                  project.latestReportTimestamp = project.timestamp || 
                    (project.date ? new Date(project.date).getTime() : 0);
                }
                
                return project;
              } catch (error) {
                console.error(`Error fetching reports for project ${project.id}:`, error);
                // Fallback to project date
                project.latestReportDate = project.date;
                project.latestReportTimestamp = project.timestamp || 
                  (project.date ? new Date(project.date).getTime() : 0);
                return project;
              }
            })
          );
          
          // Step 3: Sort projects by the latest report date
          const sortedProjects = projectsWithLatestReports.sort((a, b) => {
            const timestampA = a.latestReportTimestamp || 0;
            const timestampB = b.latestReportTimestamp || 0;
            
            // Sort newest first or oldest first based on user selection
            return sortOrder === "newest" ? timestampB - timestampA : timestampA - timestampB;
          });

          setData(sortedProjects);
        } catch (error) {
          console.error("Error fetching projects:", error);
          setData([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [searchValue, user, render, sortOrder]);

  return (
    <React.Fragment>
      <Navbar />

      <div className="my-4 d-flex align-items-end">
        <Link
          style={{ width: "fit-content", position: "absolute" }}
          className="text-decoration-none"
          to="/dashboard"
        >
          <div className="mx-3 d-flex align-items-center">
            <Image className="mb-3" src={images.leftArrow} alt="back_arrow" />
            <p className="d-none d-sm-inline mx-2 backToDashboard">
              {t('activeProjects:backToDashboard')}
            </p>
          </div>
        </Link>
        <h1 className="newProjects mx-auto">{t('activeProjects:title')}</h1>
      </div>
      <div className="d-block d-sm-flex justify-content-between mx-4 mt-3">
        <div 
          className="d-flex justify-content-between align-items-center mt-2"
          style={{ cursor: "pointer" }}
          onClick={toggleSortOrder}
        >
          <span>
            {t('activeProjects:sortBy')}: {sortOrder === "newest" ? 
              t('activeProjects:newestFirst') : 
              t('activeProjects:oldestFirst')
            }
          </span>
          <img src={images.ChevronDown} alt="chevron_down" />
        </div>
        <div className="mt-3 searchDiv">
          <img src={images.SearchIcon} alt="Search Icon" className="searchIcon" />
          <input
            className="activeSearch"
            type="text"
            placeholder={t('activeProjects:search.placeholder')}
            onChange={(e) => {
              const value = e.target.value.trim().toLocaleLowerCase();
              SetSearchValue(value);
            }}
          />
        </div>
      </div>
      {loading ? (
        <div className="text-center my-5">
          <h3>{t('activeProjects:loading')}</h3>
        </div>
      ) : data.length > 0 ? (
        <Container fluid className="my-5">
          <Row style={{ marginBottom: "180px" }} className="gy-3">
            {data.map((value: ProjectData) => (
              <Col key={value.id} xl={3} lg={4} md={5} sm={6}>
                <ActiveCard
                  onStatusChange={handleStatusChange}
                  data={value}
                />
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <div className="text-center nothingToShow">
          <h2>{t('activeProjects:noResults')}</h2>
        </div>
      )}
      <Footer />
    </React.Fragment>
  );
};

export default ActiveProjects;
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 28/03/2025 - 20:24:33
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  Accordion,
  Image,
  Form,
  OverlayTrigger,
  Button,
  Tooltip,
} from "react-bootstrap";
import {
  addVerticalPolygon,
  addVerticalSlopeArea,
  deleteVerticalHeights,
  removeVerticalPolygon,
  setVerticalLowerHeight,
  setVerticalUpperHeight,
  CalculateGlare,
  setVerticalAzimutyhValue,
  deleteAzimuthValue,
  setVeticalAverageElevation,
  removeVerticalAvgElevation,
  setVerticalAzimuthCheck,
  setVerticalElevationCheck,
  deleteVerticalchecked,
  setVerticalSwap,
  deleteVerticalSwap,
} from "../../../../store/reducers/bmapSlice";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { api } from "../../../../api/data";
import AddDetectionPoint from "../../../../images/Add Facade PV Area Vector.png";
// import icons
import { IoInformationCircleOutline } from "react-icons/io5";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { putData } from "../../../../utils/axios";
import IconWrapper from "../../../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';

type elevationData = {
  upperEdge: {
    elevation: any;
    lat: number;
    lon: number;
    offset: number;
    tag: string;
  };
  lowerEdge: {
    elevation: any;
    lat: number;
    lon: number;
    offset: number;
    tag: string;
  };
};

const VerticalPvAreaPage: React.FC<{ projectName: string }> = ({
  projectName,
}) => {
  const polygons = useSelector(
    (state: RootState) => state.bmap.verticalPolygons
  );
  const verticalAzimuth = useSelector(
    (state: RootState) => state.bmap.verticalAzimuthValue
  );
  const vertivalSlopeArea = useSelector(
    (state: RootState) => state.bmap.verticalSlopeArea
  );
  const verticalLowerHeight = useSelector(
    (state: RootState) => state.bmap.verticalLowerHeight
  );
  const verticalUpperHeight = useSelector(
    (state: RootState) => state.bmap.verticalUpperHeight
  );
  const selectedArea = useSelector(
    (state: RootState) => state.bmap.selectedArea
  );
  const averageElevation = useSelector(
    (state: RootState) => state.bmap.verticalAverageElevation
  );
  const checkedAzimuth = useSelector(
    (state: RootState) => state.bmap.verticalAzimuthChecked
  );
  const checkedAvgElevation = useSelector(
    (state: RootState) => state.bmap.verticalElevationChecked
  );
  const verticalSwap = useSelector(
    (state: RootState) => state.bmap.verticalSwap
  );
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const dispatch = useDispatch();
  const [isDropdownExpanded, setIsDropdownExpanded] = useState<boolean[]>([]);
  const [elevationData, setElevationData] = useState<elevationData[]>([]);
  const [polygonLength, setPolygonLength] = useState<number>(0);
  const [widthValues, setWidthValues] = useState<number[]>([]);
  

  // Track which polygons have completed elevation fetching
  const [elevationFetchStatus, setElevationFetchStatus] = useState<{[index: number]: boolean}>({});
  // Track which polygons have completed azimuth calculation
  const [azimuthCalcStatus, setAzimuthCalcStatus] = useState<{[index: number]: boolean}>({});
  const { t } = useTranslation(['verticalPvArea', 'common']);
  
  // Function to save all data to localStorage, preserving existing entries
  const saveToLocalStorage = () => {
    // Read any existing data first
    let azimuthDataArray: Array<{
      azimuth: number;
      polygonData: elevationData;
    }> = [];
    
    const existingData = localStorage.getItem('AzimuthVerticalDataArray');
    
    if (existingData) {
      try {
        azimuthDataArray = JSON.parse(existingData);
        console.log("Loaded existing vertical azimuth data:", azimuthDataArray);
      } catch (error) {
        console.error('Error parsing existing data from localStorage:', error);
        azimuthDataArray = [];
      }
    }
    
    // Update data for existing polygons
    for (let i = 0; i < elevationData.length; i++) {
      if (!elevationData[i] || !verticalAzimuth[i]) continue;
      
      // Find if there's an existing entry for this polygon
      const existingIndex = azimuthDataArray.findIndex(item => 
        item.polygonData?.upperEdge?.lat === elevationData[i]?.upperEdge?.lat &&
        item.polygonData?.upperEdge?.lon === elevationData[i]?.upperEdge?.lon &&
        item.polygonData?.lowerEdge?.lat === elevationData[i]?.lowerEdge?.lat &&
        item.polygonData?.lowerEdge?.lon === elevationData[i]?.lowerEdge?.lon
      );
      
      if (existingIndex >= 0) {
        // Update existing entry
        azimuthDataArray[existingIndex] = {
          azimuth: Number(verticalAzimuth[i]),
          polygonData: elevationData[i]
        };
      } else {
        // Add new entry
        azimuthDataArray.push({
          azimuth: Number(verticalAzimuth[i]),
          polygonData: elevationData[i]
        });
      }
    }
    
    // Filter out entries that no longer match any polygon
    const filteredDataArray = azimuthDataArray.filter(item => {
      return elevationData.some(ed => 
        ed?.upperEdge?.lat === item.polygonData?.upperEdge?.lat &&
        ed?.upperEdge?.lon === item.polygonData?.upperEdge?.lon &&
        ed?.lowerEdge?.lat === item.polygonData?.lowerEdge?.lat &&
        ed?.lowerEdge?.lon === item.polygonData?.lowerEdge?.lon
      );
    });
    
    // Save to localStorage
    localStorage.setItem("AzimuthVerticalDataArray", JSON.stringify(filteredDataArray));
    console.log("Saved vertical PV data to localStorage:", filteredDataArray);
  };
  
  const fetchElevations = async (specificIndex?: number) => {
    try {
      // If a specific index is provided, only fetch that one polygon
      const polygonsToProcess = specificIndex !== undefined 
        ? [{ polygon: polygons[specificIndex], index: specificIndex }]
        : polygons.map((polygon, index) => ({ polygon, index }));
      
      const updatedElevationData = [...elevationData]; // Create a copy of current data
      
      // Use sequential processing instead of Promise.all to be more stable
      for (const { polygon, index } of polygonsToProcess) {
        // Skip if we've already processed this polygon and it's not specifically requested
        if (elevationFetchStatus[index] && specificIndex !== index) {
          console.log(`Skipping elevation fetch for vertical polygon ${index} (already processed)`);
          continue;
        }
        
        console.log(`Fetching elevations for vertical polygon ${index}`);
        
        try {
          const upperCoords = polygon[0];
          const lowerCoords = polygon[1];
          
          if (!upperCoords?.lat || !upperCoords?.lng || !lowerCoords?.lat || !lowerCoords?.lng) {
            console.error(`Invalid coordinates for polygon ${index}`);
            continue;
          }

          // Fetch upper edge elevation
          const upperResponse = await api.getElevation(
            upperCoords.lat,
            upperCoords.lng
          );
          const upperElevation =
            upperResponse.data[0]?.results[0]?.elevation ?? null;
          console.log("upperElevation", upperElevation);

          // Fetch lower edge elevation
          const lowerResponse = await api.getElevation(
            lowerCoords.lat,
            lowerCoords.lng
          );
          const lowerElevation =
            lowerResponse.data[0]?.results[0]?.elevation ?? null;
          console.log("lowerElevation", lowerElevation);
          
          // Calculate average elevation if both points have elevation data
          if (upperElevation !== null && lowerElevation !== null) {
            const averageElevation =
              (Number(lowerElevation) + Number(upperElevation)) / 2;
              
            if (checkedAvgElevation[index]) {
              dispatch(
                setVeticalAverageElevation({
                  index,
                  value: averageElevation.toFixed(1),
                })
              );
            }
            
            // Mark this polygon as processed
            setElevationFetchStatus(prev => ({
              ...prev,
              [index]: true
            }));
            
            console.log(`Elevation fetched for vertical polygon ${index}: Upper=${upperElevation}, Lower=${lowerElevation}`);
            
            // Update the elevation data for this index
            updatedElevationData[index] = {
              upperEdge: {
                elevation: upperElevation,
                lat: upperCoords.lat,
                lon: upperCoords.lng,
                offset: 0,
                tag: "upper_edge",
              },
              lowerEdge: {
                elevation: lowerElevation,
                lat: lowerCoords.lat,
                lon: lowerCoords.lng,
                offset: 0,
                tag: "lower_edge",
              },
            };
          } else {
            console.error(`Invalid elevation data for vertical polygon ${index}`);
          }
        } catch (apiError) {
          console.error(`Error fetching elevation for polygon ${index}:`, apiError);
          toast.error(t('verticalPvArea:errors.elevationFetching'));
        }
      }
      
      // Update state with the modified data
      setElevationData(updatedElevationData);
    } catch (error) {
      console.error("Error in fetchElevations:", error);
      toast.error(t('verticalPvArea:errors.elevationFetching'));
    }
  };

  useEffect(() => {
    // Only run if we have elevation data
    if (elevationData.length === 0) return;
    
    const getAzimuthValue = async () => {
      // Get existing data first
      let azimuthDataArray = [];
      const existingData = localStorage.getItem('AzimuthVerticalDataArray');
      
      if (existingData) {
        try {
          const parsedData = JSON.parse(existingData);
          
          // Validate that it's an array and contains proper objects
          if (Array.isArray(parsedData) && parsedData.every(item => 
              typeof item === 'object' && 
              item.hasOwnProperty('azimuth') && 
              item.hasOwnProperty('polygonData'))) {
            azimuthDataArray = parsedData;
            console.log("Loaded existing vertical azimuth data:", azimuthDataArray);
          } else {
            console.warn("Existing data in localStorage has incorrect format, starting fresh");
          }
        } catch (error) {
          console.error('Error parsing existing data from localStorage:', error);
        }
      }
      
      for (let i = 0; i < elevationData.length; i++) {
        // Skip if elevation data is missing
        if (!elevationData[i]?.upperEdge?.elevation || !elevationData[i]?.lowerEdge?.elevation) {
          console.log(`Skipping vertical polygon ${i}: Missing elevation data`);
          continue;
        }
        
        if (verticalLowerHeight[i] === null && verticalUpperHeight[i] === null && checkedAzimuth[i]) {
          if (checkedAzimuth[i]) {
            dispatch(
              setVerticalAzimutyhValue({
                index: i,
                azimuth: null,
              })
            );
          }
          continue;
        }

        // Process if we have all required data
        if (verticalLowerHeight[i] != null && 
            verticalUpperHeight[i] != null && 
            checkedAzimuth[i] === true) {
            
          const result = {
            area_type: "vertical",
            point1: {
              lat: elevationData[i]?.upperEdge?.lat,
              lon: elevationData[i]?.upperEdge?.lon,
              elevation: elevationData[i]?.upperEdge?.elevation,
              offset: 0,
              tag: "upper_edge",
            },
            point2: {
              lat: elevationData[i]?.lowerEdge?.lat, 
              lon: elevationData[i]?.lowerEdge?.lon,  
              elevation: elevationData[i]?.lowerEdge?.elevation,
              offset: 0,
              tag: "upper_edge",
            },
            point3: {
              lat: elevationData[i]?.lowerEdge?.lat,
              lon: elevationData[i]?.lowerEdge?.lon,
              elevation: elevationData[i]?.lowerEdge?.elevation,
              offset: 0,
              tag: "lower_edge",
            },
            point4: {
              lat: elevationData[i]?.upperEdge?.lat, 
              lon: elevationData[i]?.upperEdge?.lon,
              elevation: elevationData[i]?.upperEdge?.elevation,
              offset: 0,
              tag: "lower_edge",
            },
          };

          try {
            console.log(`Processing vertical azimuth for polygon ${i}`);
            
            const responseData = await putData<{ aziumth: number }, typeof result>(
              '/azimuth/',
              result,
              {
                headers: { 'Content-Type': 'application/json' },
              }
            );
        
            const azimuthResult = responseData.aziumth;
            console.log(`Got vertical azimuth for polygon ${i}: ${azimuthResult}`);
            
            dispatch(
              setVerticalAzimutyhValue({
                index: i,
                azimuth: azimuthResult.toFixed(1),
              })
            );

            // Create the data structure for this polygon
            const polygonData = {
              upperEdge: elevationData[i].upperEdge,
              lowerEdge: elevationData[i].lowerEdge
            };

            // Check if an entry for this polygon already exists
            const existingEntryIndex = azimuthDataArray.findIndex(
              (item) => item.polygonData && 
                      item.polygonData.upperEdge && 
                      item.polygonData.upperEdge.lat === elevationData[i].upperEdge.lat && 
                      item.polygonData.upperEdge.lon === elevationData[i].upperEdge.lon
            );
            
            if (existingEntryIndex !== -1) {
              // Update existing entry
              azimuthDataArray[existingEntryIndex] = { 
                azimuth: azimuthResult, 
                polygonData: polygonData 
              };
            } else {
              // Add new entry
              azimuthDataArray.push({ 
                azimuth: azimuthResult, 
                polygonData: polygonData 
              });
            }
          } catch (err) {
            console.error(`Error fetching vertical azimuth data for index ${i}:`, err);
          }
        }
      }
      
      // Validate the data before saving
      const validDataArray = azimuthDataArray.filter(item => 
        item && 
        typeof item === 'object' && 
        item.hasOwnProperty('azimuth') && 
        item.hasOwnProperty('polygonData')
      );
      
      // Save the final array to localStorage
      localStorage.setItem("AzimuthVerticalDataArray", JSON.stringify(validDataArray));
      console.log("Saved vertical azimuth data to localStorage:", validDataArray);
    };

    // Call the function
    getAzimuthValue();
  }, [elevationData, checkedAzimuth, verticalUpperHeight, verticalLowerHeight, dispatch]);

  // Function to trigger a re-fetch for a specific polygon
  // Debounced version of the refetch function to prevent multiple rapid API calls
  const refetchPolygonElevation = (index: number) => {
    console.log(`Scheduling re-fetch of elevation data for polygon ${index}`);
    
    // If already dragging, don't do anything - we'll handle the fetch when dragging stops
    if (isDraggingRef.current) {
      console.log(`Skipping refetch during active drag for polygon ${index}`);
      return;
    }
    
    // Reset the fetch status for this index to force a new fetch
    setElevationFetchStatus(prev => ({
      ...prev,
      [index]: false
    }));
    
    // Reset the azimuth calculation status
    setAzimuthCalcStatus(prev => ({
      ...prev,
      [index]: false
    }));
    
    // Use setTimeout to ensure we don't call the API too frequently
    setTimeout(() => {
      console.log(`Executing re-fetch of elevation data for polygon ${index}`);
      fetchElevations(index);
    }, 300);
  };

  // Trigger elevation fetching only when polygon count changes or auto-calculation is toggled
  useEffect(() => {
    // This effect only runs when polygons are added/removed or auto-calculate is toggled
    // It won't run during dragging operations since we handle that separately
    let timeout: NodeJS.Timeout | null = null;

    const delayedFetch = () => {
      if (timeout) clearTimeout(timeout);
      
      // Only fetch if we're not in the middle of a drag operation
      if (!isDraggingRef.current) {
        timeout = setTimeout(() => {
          console.log("Fetching elevations due to polygon count change or auto-calculation toggle");
          fetchElevations();
        }, 1000);
      }
    };

    delayedFetch();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [polygons.length, checkedAvgElevation]);
  
  // We'll remove the separate map event listener since we're detecting changes 
  // through the polygon coordinates directly

  // Save data when component unmounts
  useEffect(() => {
    return () => {
      saveToLocalStorage();
    };
  }, []);
  
  useEffect(() => {
    const polygonLength = polygons.length - 1;
    if (polygonLength >= 0) {
      dispatch(setVerticalSwap({ index: polygonLength, checked: false }));
      if (checkedAvgElevation[polygonLength] === undefined) {
        dispatch(
          setVerticalElevationCheck({ index: polygonLength, check: true })
        );
      }
      if (checkedAzimuth[polygonLength] === undefined) {
        dispatch(setVerticalAzimuthCheck({ index: polygonLength, check: true }));
      }
    }
  }, [polygons.length]);

  const handleDeletePolygon = (index: number) => {
    // Update our internal tracking first
    setElevationFetchStatus(prev => {
      const newStatus = {...prev};
      delete newStatus[index];
      // Reindex remaining entries
      const updatedStatus: {[key: number]: boolean} = {};
      Object.entries(newStatus).forEach(([key, value]) => {
        const numKey = parseInt(key);
        if (numKey > index) {
          updatedStatus[numKey - 1] = value;
        } else {
          updatedStatus[numKey] = value;
        }
      });
      return updatedStatus;
    });
    
    setAzimuthCalcStatus(prev => {
      const newStatus = {...prev};
      delete newStatus[index];
      // Reindex remaining entries
      const updatedStatus: {[key: number]: boolean} = {};
      Object.entries(newStatus).forEach(([key, value]) => {
        const numKey = parseInt(key);
        if (numKey > index) {
          updatedStatus[numKey - 1] = value;
        } else {
          updatedStatus[numKey] = value;
        }
      });
      return updatedStatus;
    });
    
    // Then update Redux state and local state
    dispatch(removeVerticalPolygon(index));
    dispatch(deleteVerticalHeights({ index }));
    
    setElevationData(prev => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
    
    dispatch(deleteAzimuthValue({ index }));
    dispatch(removeVerticalAvgElevation({ index }));
    dispatch(deleteVerticalchecked({ index }));
    dispatch(deleteVerticalSwap({ index }));
    
    // Save to localStorage after deletion
    setTimeout(() => {
      saveToLocalStorage();
    }, 300);
  };

  const oneDigitAfterPoint = (enterValue: string) => {
    let value = enterValue;
    if (value.includes(".")) {
      const parts = value.split(".");
      if (parts[1].length > 1) {
        value = `${parts[0]}.${parts[1].slice(0, 1)}`;
      }
    }
    return value;
  };
  
  // Track previous polygon coordinates to only fetch when dragging ends
  const prevPolygonsRef = useRef<typeof polygons>([]); 
  const isDraggingRef = useRef<boolean>(false);
  const dragTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  // Calculate width for each polygon and store in widthValues state
  useEffect(() => {
    const newWidthValues = polygons.map((coord, index) => {
      const width = google?.maps?.geometry?.spherical.computeDistanceBetween(
        new google.maps.LatLng(coord[0]?.lat, coord[0]?.lng),
        new google.maps.LatLng(coord[1]?.lat, coord[1]?.lng)
      );
      return parseFloat(width.toFixed(1));
    });
    setWidthValues(newWidthValues);
    
    // Only process if we have polygons and previous polygons to compare
    if (polygons.length > 0 && prevPolygonsRef.current.length > 0) {
      // Compare the current polygons with the previous ones
      polygons.forEach((polygon, index) => {
        const prevPolygon = prevPolygonsRef.current[index];
        if (!prevPolygon) return; // Skip if no previous polygon
        
        const prevUpperLat = prevPolygon[0]?.lat;
        const prevUpperLng = prevPolygon[0]?.lng;
        const prevLowerLat = prevPolygon[1]?.lat;
        const prevLowerLng = prevPolygon[1]?.lng;
        
        const newUpperLat = polygon[0]?.lat;
        const newUpperLng = polygon[0]?.lng;
        const newLowerLat = polygon[1]?.lat;
        const newLowerLng = polygon[1]?.lng;
        
        // Check if coordinates have changed
        const epsilon = 0.0000001; // Small threshold
        const hasChanged = 
          Math.abs(prevUpperLat - newUpperLat) > epsilon ||
          Math.abs(prevUpperLng - newUpperLng) > epsilon ||
          Math.abs(prevLowerLat - newLowerLat) > epsilon ||
          Math.abs(prevLowerLng - newLowerLng) > epsilon;
        
        // If coordinates have changed, we're in a dragging state
        if (hasChanged) {
          console.log(`Polygon ${index} coordinates changing, possible drag in progress`);
          isDraggingRef.current = true;
          
          // Clear any existing timeout
          if (dragTimeoutRef.current) {
            clearTimeout(dragTimeoutRef.current);
          }
          
          // Set a new timeout - if no changes for 500ms, consider drag finished
          dragTimeoutRef.current = setTimeout(() => {
            console.log(`Drag appears to be finished for polygon ${index}, fetching elevation`);
            isDraggingRef.current = false;
            
            // Reset fetch status for this polygon
            setElevationFetchStatus(prev => ({
              ...prev,
              [index]: false
            }));
            
            // Fetch elevation for this polygon
            refetchPolygonElevation(index);
          }, 500);
        }
      });
    }
    
    // Update previous polygons reference
    prevPolygonsRef.current = JSON.parse(JSON.stringify(polygons));
  }, [polygons]);

  useEffect(() => {
    if (polygons.length > 0 && polygons.length >= polygonLength) {
      setIsDropdownExpanded((prev) => [
        ...new Array(polygons.length - 1).fill(false),
        true,
      ]);
      setPolygonLength(polygons.length);
    }
  }, [polygons]);

  const toggleDropdown = (index: number) => {
    const newDropdownState = [...isDropdownExpanded];
    newDropdownState[index] = !newDropdownState[index];
    setIsDropdownExpanded(newDropdownState);
  };

  // Handle swapping edges
  const handleSwapEdges = (index: number, value: any, isChecked: boolean) => {
    // Swap the coordinates
    const newUpperCoords = value[1];
    const newLowerCoords = value[0];
    
    dispatch(
      addVerticalPolygon({
        index,
        verticalPolygon: [
          {
            lat: newUpperCoords.lat,
            lng: newUpperCoords.lng,
          },
          {
            lat: newLowerCoords.lat,
            lng: newLowerCoords.lng,
          },
        ],
      })
    );
    
    // Reset the elevation fetch status for this index to force a new fetch
    setElevationFetchStatus(prev => ({
      ...prev,
      [index]: false
    }));
    
      // Reset the elevation fetch status without modifying the data directly
    setElevationFetchStatus(prev => ({
      ...prev,
      [index]: false
    }));
    
    // Update Redux state
    dispatch(
      setVerticalSwap({
        index,
        checked: isChecked,
      })
    );
    
    // Force re-fetch after a short delay to ensure state updates have completed
    setTimeout(() => {
      refetchPolygonElevation(index);
    }, 300);
  };

  const groundElevationTooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('verticalPvArea:tooltips.groundElevation')}
      </small>
    </Tooltip>
  );

 
  const Azimuthtooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('verticalPvArea:tooltips.azimuth')}
      </small>
    </Tooltip>
  );


  const tiltedAngle = (
    <Tooltip id="tooltip">
      <small>
        {t('verticalPvArea:tooltips.tiltAngle')}
      </small>
    </Tooltip>
  );

  return (
    <div>
      {(selectedArea?.value == "vertical" ||
        selectedArea?.value == "verticalTab") &&
        polygons.length < 1 && (
          <div className="verticalNothingToShow">
            <div>
              <Image
                src={AddDetectionPoint}
                alt="addTiltedPVarea"
                loading="lazy"
              />
              <strong>{t('verticalPvArea:emptyState.title')}</strong>
              <small>
                {t('verticalPvArea:emptyState.description')}
              </small>
            </div>
          </div>
        )}
      {polygons.length > 0 &&
        (selectedArea?.value == "vertical" ||
          selectedArea?.value == "verticalTab") && (
          <div style={{ height: "76vh", overflowY: "auto" }}>
            {polygons.map((value, index) => {
              return (
                <div className="VerticalPvAreaPage mb-2" key={`vertical-pv-${index}`}>
                  <Accordion
                    activeKey={isDropdownExpanded[index] ? `${index}` : null}
                  >
                    <Accordion.Item eventKey={`${index}`}>
                      <Accordion.Header onClick={() => toggleDropdown(index)}>
                        <p className="projectName my-auto">
                          {`${t('verticalPvArea:card.prefix')} - ${index + 1}`} &nbsp; |
                          <IconWrapper Icon={MdDeleteOutline} size={17}
                            color="red"
                            className="mb-1"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent accordion toggle
                              handleDeletePolygon(index);
                            }} />
                        </p>
                        <p className={`collapseBtn my-auto`}>
                          {isDropdownExpanded[index] ? (
                            <span>
                              {t('verticalPvArea:card.collapse')} <IconWrapper Icon={RxCaretUp} size={20} />
                            </span>
                          ) : (
                            <span>
                              {t('verticalPvArea:card.expand')} <IconWrapper Icon={RxCaretDown} size={20} />
                            </span>
                          )}
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">
                            {t('verticalPvArea:sections.areaDimensions')}
                          </p>
                        </div>
                        <div className="coolinput">
                          <label htmlFor="input" className="text">
                            {t('verticalPvArea:fields.length')}
                          </label>
                          <input
                            type="text"
                            name="input"
                            className="input disabledInput"
                            value={`${widthValues[index] || 0}`}
                            readOnly
                          />
                        </div>
                        <p className="m-0 mt-4">
                          {t('verticalPvArea:sections.altitudeParameters')}
                        </p>
                        <div className="d-flex justify-content-between mt-1">
                          <div className="coolinput">
                            <label htmlFor="input" className="text">
                              {t('verticalPvArea:fields.upperEdgeHeight')}
                            </label>
                            <input
                              step="0.01"
                              type="number"
                              name="input"
                              className="input"
                              onChange={(e) => {
                                const value = oneDigitAfterPoint(e.target.value);
                                if (Number(value) < Number(verticalLowerHeight[index])) {
                                  toast.error(t('verticalPvArea:errors.upperEdgeHeight'));
                                }
                                dispatch(
                                  setVerticalUpperHeight({
                                    index,
                                    floatValue: value === "" ? null : value,
                                  })
                                );
                              }}
                              value={
                                verticalUpperHeight[index] === null
                                  ? ""
                                  : verticalUpperHeight[index]
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="coolinput">
                              <label htmlFor="input" className="text">
                                {t('verticalPvArea:fields.lowerEdgeHeight')}
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                name="input"
                                className="input"
                                onChange={(e) => {
                                  const value = oneDigitAfterPoint(e.target.value);
                                  if (Number(verticalUpperHeight[index]) < Number(value)) {
                                    toast.error(t('verticalPvArea:errors.upperEdgeHeight'));
                                  }
                                  dispatch(
                                    setVerticalLowerHeight({
                                      index,
                                      floatValue: value === "" ? null : value,
                                    })
                                  );
                                }}
                                value={
                                  verticalLowerHeight[index] === null
                                    ? ""
                                    : verticalLowerHeight[index]
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-1 mt-1">
                            <div className="coolinput">
                              <label htmlFor="input" className="text">
                                {t('verticalPvArea:fields.groundElevation')}
                              </label>
                              <input
                                onChange={(e) => {
                                  const value = oneDigitAfterPoint(e.target.value);
                                  dispatch(
                                    setVeticalAverageElevation({
                                      index,
                                      value: value === "" ? null : value,
                                    })
                                  );
                                }}
                                type="text"
                                name="input"
                                className={
                                  checkedAvgElevation[index]
                                    ? "disabledInput input"
                                    : "input"
                                }
                                disabled={checkedAvgElevation[index] ? true : false}
                                value={
                                  averageElevation[index] === null
                                    ? ""
                                    : averageElevation[index]
                                }
                              />
                              <div className="d-flex align-items-center">
                                <Form.Check
                                  checked={checkedAvgElevation[index]}
                                  onChange={(e) => {
                                    dispatch(
                                      setVerticalElevationCheck({
                                        index,
                                        check: e.target.checked,
                                      })
                                    );
                                    
                                    // If auto-calculation is turned on, trigger a refetch
                                    if (e.target.checked) {
                                      refetchPolygonElevation(index);
                                    }
                                  }}
                                />
                                <label
                                  htmlFor="upperEdge"
                                  className="ms-2"
                                  style={{
                                    fontSize: "12px",
                                    color: "#858585 ",
                                  }}
                                >
                                  {t('verticalPvArea:fields.autoCalculate')}
                                </label>
                              </div>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={groundElevationTooltip}
                            >
                              <Button variant="none" className="mt-0 p-0">
                                <IconWrapper Icon={IoInformationCircleOutline} size={20} color="#0984E3"/>
                              </Button>
                            </OverlayTrigger>
                          </div>
                          <div
                            className="default"
                            style={{ marginTop: "25px" }}
                          >
                            <div className="d-flex justify-content-between align-items-center px-2 pt-2">
                              <p className="m-0 defaultText">
                                {verticalSwap[index] ? t('verticalPvArea:fields.swapped') : t('verticalPvArea:fields.default')}
                              </p>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={verticalSwap[index]}
                                  onChange={(e) => {
                                    // Use the new handler function for swapping edges
                                    handleSwapEdges(index, value, e.target.checked);
                                  }}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                            <strong className="floatingLable">
                              {t('verticalPvArea:fields.swapEdges')}
                            </strong>
                          </div>
                        </div>
                        <p className="m-0 mt-4">
                          {t('verticalPvArea:sections.angleParameters')}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <div className="coolinput">
                            <label htmlFor="input" className="text">
                              {t('verticalPvArea:fields.tiltAngle')}
                            </label>
                            <input
                              onChange={(e) => {
                                const value = oneDigitAfterPoint(e.target.value);
                                dispatch(
                                  addVerticalSlopeArea({
                                    index,
                                    value: value === "" ? null : value,
                                  })
                                );
                              }}
                              type="number"
                              name="input"
                              className="input"
                              value={
                                vertivalSlopeArea[index] === null
                                  ? ""
                                  : vertivalSlopeArea[index]
                              }
                            />
                          </div>

                          <OverlayTrigger placement="top" overlay={tiltedAngle}>
                            <Button variant="none" className="mt-3 p-0">
                              <IconWrapper Icon={IoInformationCircleOutline} size={20} color="#0984E3" className="mt-3"/>
                            </Button>
                          </OverlayTrigger>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="coolinput">
                            <label htmlFor="input" className="text">
                              {t('verticalPvArea:fields.azimuth')}
                            </label>
                            <input
                              onChange={(e) => {
                                const value = oneDigitAfterPoint(e.target.value);
                                dispatch(
                                  setVerticalAzimutyhValue({
                                    index,
                                    azimuth: value === "" ? null : value,
                                  })
                                );
                              }}
                              type="number"
                              step="0.01"
                              name="input"
                              className={
                                checkedAzimuth[index]
                                  ? "disabledInput input"
                                  : "input"
                              }
                              disabled={checkedAzimuth[index]}
                              placeholder={
                                checkedAzimuth[index] === true
                                  ? t('verticalPvArea:fields.provideHeightDataFirst')
                                  : ``
                              }
                              value={
                                verticalAzimuth[index] === null && verticalLowerHeight[index] === null && verticalUpperHeight[index] === null
                                  ? " "
                                  : verticalAzimuth[index]
                              }
                            />
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={Azimuthtooltip}
                          >
                            <Button variant="none" className="mt-3 p-0">
                              <IconWrapper Icon={IoInformationCircleOutline} size={20} color="#0984E3"/>
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            checked={checkedAzimuth[index]}
                            onChange={(e) => {
                              dispatch(
                                setVerticalAzimuthCheck({
                                  index,
                                  check: e.target.checked,
                                })
                              );
                              
                              // If auto-calculation is turned on, trigger elevation API first
                              if (e.target.checked && verticalLowerHeight[index] !== null && verticalUpperHeight[index] !== null) {
                                // First make sure elevation data is current
                                refetchPolygonElevation(index);
                              }
                            }}
                          />
                          <label
                            htmlFor="upperEdge"
                            className="ms-2"
                            style={{ fontSize: "12px", color: "#858585 " }}
                          >
                            {t('verticalPvArea:fields.autoCalculate')}
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default VerticalPvAreaPage;
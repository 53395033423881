/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 16:54:07
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { I18N_CONFIG } from '../../utils/config';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  const handleLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    
    // Update HTML attributes for RTL support
    const isRTL = I18N_CONFIG.supportedLanguages.find(
      lang => lang.code === languageCode
    )?.rtl || false;
    
    document.documentElement.lang = languageCode;
    document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
    
    // Save preference to localStorage
    localStorage.setItem('i18nextLng', languageCode);
  };

  // Simple emoji flags for languages
  const getLanguageFlag = (code: string): string => {
    switch (code) {
      case 'en': return '🇬🇧';
      case 'de': return '🇩🇪';
      default: return '🌐';
    }
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="light" id="language-dropdown" className="bg-transparent border-0">
        <span style={{ fontSize: '1.2rem' }}>
          {getLanguageFlag(currentLanguage)}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {I18N_CONFIG.supportedLanguages.map(lang => (
          <Dropdown.Item 
            key={lang.code}
            active={currentLanguage === lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            className="d-flex align-items-center"
          >
            <span style={{ fontSize: '1.2rem', marginRight: '8px' }}>
              {getLanguageFlag(lang.code)}
            </span>
            {lang.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 14/02/2025 - 19:11:21
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 14/02/2025
    * - Author          : 
    * - Modification    : 
**/

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig
} from 'axios';


const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://solarglare.work'
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Modify request config if needed
    // e.g., config.headers.Authorization = 'Bearer TOKEN';
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise.reject(error)
);

/**
 * GET request
 * 
 * @param endpoint - The endpoint path (relative to baseURL)
 * @param config - Optional Axios config
 * @returns - Promise of the typed response data
 */
export async function getData<T>(
  endpoint: string,
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axiosInstance.get<T>(
      endpoint,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * POST request
 * 
 * @param endpoint - The endpoint path
 * @param data - The request body data
 * @param config - Optional Axios config
 * @returns - Promise of the typed response data
 */
export async function postData<T, R = unknown>(
  endpoint: string,
  data: R,
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axiosInstance.post<T>(
      endpoint,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * PUT request
 * 
 * @param endpoint - The endpoint path
 * @param data - The request body data
 * @param config - Optional Axios config
 * @returns - Promise of the typed response data
 */
export async function putData<T, R = unknown>(
  endpoint: string,
  data: R,
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axiosInstance.put<T>(
      endpoint,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * DELETE request
 * 
 * @param endpoint - The endpoint path
 * @param config - Optional Axios config (e.g., to pass query params)
 * @returns - Promise of the typed response data
 */
export async function deleteData<T>(
  endpoint: string,
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axiosInstance.delete<T>(
      endpoint,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export { axiosInstance };

/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 16:11:08
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/
import { I18nConfig } from './type';

export const I18N_CONFIG: I18nConfig = {
  defaultLanguage: 'en',
  supportedLanguages: [
    { code: 'en', name: 'English', rtl: false },
    { code: 'de', name: 'Deutsch', rtl: false }
  ]
};
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 03/03/2025 - 22:16:51
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 03/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import images from "../../images/index";
import { Image } from "react-bootstrap";
import Navbar from "../../components/shared/navbar";
import Footer from "../../components/shared/footer";
import ReportCard from "../../components/pages/reportCard";
import Container from "react-bootstrap/Container";
import { db, auth } from "../../firebase";
import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";
import { useTranslation } from 'react-i18next';


// Interface for report card data
interface ReportCardData {
  date: string;
  id: string;
  projectName: string;
  totalCalcualetdReports: number;
  userId: string;
  calculated?: boolean;
  status?: string;
  timestamp?: number;
  latestReportDate?: string; // Store the most recent report date
  latestReportTimestamp?: number; // Store the timestamp for sorting
  [key: string]: any;
}

const MyReports: React.FC = () => {
  const [data, setData] = useState<ReportCardData[]>([]);
  const [render, setRender] = useState<boolean>(true);
  const [searchValue, SetSearchValue] = useState("");
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");
  const [loading, setLoading] = useState<boolean>(true);
  const user = auth.currentUser?.uid;
  const { t } = useTranslation(['myReports', 'common']);

  useEffect(() => {
    const fetchDataAndSort = async () => {
      if (!user) return;
      
      setLoading(true);
      
      try {
        // Step 1: Get all active calculated projects
        let projectsQuery;
        if (searchValue.trim() === "") {
          projectsQuery = query(
            collection(db, "projects"),
            where("userId", "==", user),
            where("status", "==", "active"),
            where("calculated", "==", true)
          );
        } else {
          projectsQuery = query(
            collection(db, "projects"),
            where("userId", "==", user),
            where("status", "==", "active"),
            where("calculated", "==", true),
            where("projectName", ">=", searchValue),
            where("projectName", "<=", searchValue + "\uf8ff")
          );
        }
        
        const projectSnapshot = await getDocs(projectsQuery);
        const projects = projectSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          totalCalcualetdReports: doc.data().totalCalcualetdReports || 0
        } as ReportCardData));
        
        // Step 2: For each project, find the latest report date
        const projectsWithLatestReports = await Promise.all(
          projects.map(async (project) => {
            try {
              // Get all reports for this project
              const reportsQuery = query(
                collection(db, "paidReport"),
                where("userId", "==", user),
                where("projectId", "==", project.id)
              );
              
              const reportsSnapshot = await getDocs(reportsQuery);
              
              if (!reportsSnapshot.empty) {
                // Find the report with the most recent date
                let latestDate = "";
                let latestTimestamp = 0;
                
                reportsSnapshot.docs.forEach(doc => {
                  const reportData = doc.data();
                  const reportDate = reportData.date;
                  
                  if (reportDate) {
                    const reportTimestamp = new Date(reportDate).getTime();
                    
                    if (!latestDate || reportTimestamp > latestTimestamp) {
                      latestDate = reportDate;
                      latestTimestamp = reportTimestamp;
                    }
                  }
                });
                
                // Set the latest report date and timestamp
                if (latestDate) {
                  project.latestReportDate = latestDate;
                  project.latestReportTimestamp = latestTimestamp;
                } else {
                  // Fallback to project date if no reports have dates
                  project.latestReportDate = project.date;
                  project.latestReportTimestamp = new Date(project.date).getTime();
                }
              } else {
                // No reports found, use project date
                project.latestReportDate = project.date;
                project.latestReportTimestamp = new Date(project.date).getTime();
              }
              
              return project;
            } catch (error) {
              console.error(`Error fetching reports for project ${project.id}:`, error);
              // Fallback to project date
              project.latestReportDate = project.date;
              project.latestReportTimestamp = new Date(project.date).getTime();
              return project;
            }
          })
        );
        
        // Step 3: Sort projects by the latest report date
        const sortedProjects = projectsWithLatestReports.sort((a, b) => {
          const timestampA = a.latestReportTimestamp || 0;
          const timestampB = b.latestReportTimestamp || 0;
          
          // Sort newest first or oldest first based on user selection
          return sortOrder === "newest" ? timestampB - timestampA : timestampA - timestampB;
        });
        
        setData(sortedProjects);
      } catch (error) {
        console.error("Error fetching and sorting projects:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchDataAndSort();
  }, [user, searchValue, render, sortOrder]);

  const handleStatusChange = () => {
    setRender((prevRender) => !prevRender);
  };
  
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "newest" ? "oldest" : "newest");
  };

  return (
    <React.Fragment>
    <Navbar />
    <div className="my-3 d-flex align-items-end ">
      <Link
        style={{ width: "fit-content", position: "absolute" }}
        className="text-decoration-none"
        to="/dashboard"
      >
        <div className="mx-3 d-flex align-items-center">
          <Image className="mb-3" src={images.leftArrow} alt="back_arrow" />
          <p className="d-none d-sm-inline  mx-2 backToDashboard">
            {t('myReports:backToDashboard')}
          </p>
        </div>
      </Link>
      <h1 className="newProjects  mx-auto ">{t('myReports:title')}</h1>
    </div>
    <div className="d-block d-sm-flex justify-content-between mx-4  mt-3">
      <div 
        className="d-flex justify-content-between align-items-center mt-2" 
        style={{ cursor: "pointer" }}
        onClick={toggleSortOrder}
      >
        <span>{t('myReports:sortBy')}: {sortOrder === "newest" ? t('myReports:newestFirst') : t('myReports:oldestFirst')}</span>
        <img src={images.ChevronDown} alt="chevron_down" />
      </div>
      <div className="mt-3 searchDiv">
        <img src={images.SearchIcon} alt="Search Icon" className="searchIcon" />
        <input
          className="activeSearch"
          type="text"
          placeholder={t('myReports:search.placeholder')}
          onChange={(e) => {
            const value = e.target.value.trim().toLocaleLowerCase();
            SetSearchValue(value);
          }}
        />
      </div>
    </div>
    {loading ? (
      <div className="text-center my-5">
        <h3>{t('myReports:loading')}</h3>
      </div>
    ) : data.length > 0 ? (
      <Container fluid className="my-5">
        <div 
          style={{ 
            display: "flex", 
            flexWrap: "wrap", 
            marginBottom: "180px",
            gap: "20px",
            justifyContent: "flex-start"
          }}
        >
          {data.map((value: ReportCardData) => (
            <div 
              key={value.id} 
              style={{ 
                width: "calc(25% - 20px)", 
                minWidth: "280px",
                marginBottom: "20px" 
              }}
            >
              <ReportCard
                data={value}
                onStatusChange={handleStatusChange}
              />
            </div>
          ))}
        </div>
      </Container>
    ) : (
      <div className="text-center nothingToShow">
        <h2>{t('myReports:noResults')}</h2>
      </div>
    )}
    <Footer />
  </React.Fragment>
  );
};

export default MyReports;
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 28/03/2025 - 15:42:36
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import images from "../../images/index";
import { Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api/transaction";
// import FullReport from "../../../public/ass/full_report.pdf"
import { useTranslation } from "react-i18next";

const FullReport: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t } = useTranslation("fullReport");
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  const getClientSecret = async () => {
    const fetchData = await api.fetchClientSecret({amount:1900});
    navigate("/payment", {
      state: {
        paymentIntents_ID: fetchData?.data?.paymentIntents_ID,
        clientSecret: fetchData?.data?.clientSecret,
        projectId: location?.state?.projectId,
      },
    });
  };

  return (
    <div
      className="FullReportCard p-4"
      style={{ position: "sticky", top: "15px" }}
    >
      <p className="fullReport">{t("title")}</p>
      {/* <p className="PO-card-text">
        {t("subtitle")}
      </p> */}
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("features.duration", "Duration of Glare:")}</b> {t("features.durationText", "Identifying specific times when glare could occur.")}
        </small>
      </div>
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("features.affectedAreas", "Affected Areas:")}</b> {t("features.affectedAreasText", "User can select specific areas for analysis to determine where and how intensely glare occurs, enabling precise impact assessment")}
        </small>
      </div>
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("features.intensity", "Intensity of Glare Effects:")}</b> {t("features.intensityText", "Offering insights into how intense the glare could be, aiding in understanding its potential impact")}
        </small>
      </div>
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("features.localization", "Localization of Reflective Module Areas:")}</b> {t("features.localizationText", "Pinpointing the exact areas of the solar array causing reflection, facilitating the development of targeted mitigation strategies")}
        </small>
      </div>
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("features.representation", "Representation:")}</b> {t("features.representationText", "of the exact times when glare effects occur")}
        </small>
      </div>
      <div className="d-flex PO-card-data my-2">
        <Image src={images.CheckImage} width={18} height={18} />
        <small className="mx-2">
          <b>{t("pricing.label", "Amount:")}</b> {t("pricing.text", "The amount you have to pay is 19€.")}
        </small>
      </div>
      <div className="fullProfileButton d-flex flex-column align-items-center">
        <Button
          onClick={getClientSecret}
          className="my-2 d-block PO-card-purchaseFullReport"
        >
          {t("actions.purchase", "Purchase full report")}
        </Button>
        <Link to="/pdf">
          <Button variant="none" className="PO-card-seeExample">
            {t("actions.seeExample", "See example")}
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default FullReport;

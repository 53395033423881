/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 14/01/2025 - 16:29:30
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 14/01/2025
    * - Author          : 
    * - Modification    : 
**/
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const getDocumentById = async (collectionName: string, docId: string) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  
};

const updateDocument = async (collectionName: string, docId: string, data: object) => {
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
  };


export  {getDocumentById,updateDocument}
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/02/2025 - 12:25:37
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/02/2025
    * - Author          : 
    * - Modification    : 
**/
import React from 'react';
import { IconType } from 'react-icons';
import { IconBaseProps } from 'react-icons/lib';

interface IconWrapperProps {
  Icon: IconType;
  size?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  // Updated onClick type to accept an event parameter
  onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  cursor?: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  Icon,
  size,
  color,
  className,
  style,
  onClick,
  cursor,
  ...props
}) => {
  // Cast the Icon to any to bypass TypeScript's type checking
  const IconComponent = Icon as any;
  
  // Build the style object
  const combinedStyle = { 
    ...style,
    cursor: cursor || style?.cursor 
  };
  
  // Combine all props
  const iconProps: IconBaseProps & { [key: string]: any } = {
    size,
    color,
    className,
    style: combinedStyle,
    onClick,
    ...props
  };
  
  // Return the icon as a regular component with props
  return <IconComponent {...iconProps} />;
};

export default IconWrapper;
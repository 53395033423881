/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 16:51:12
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React from "react";
import Container from "react-bootstrap/Container";
import { Image, Dropdown } from "react-bootstrap";
import images from "../../images/index";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../shared/LanguageSelector";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/");
    });
  };
  
  return (
    <React.Fragment>
      <Container
        fluid
        className="navbar d-flex justify-content-between py-1 px-1 align-items-center"
      >
        <Link to="https://pv-glarecheck.com/" className="text-decoration-none">
          <div className="d-flex align-items-center">
            <Image 
              className="mx-2" 
              src={images.NavLogo} 
              alt={t('navbar.logoAlt')} 
            />
            {/* <p className="solarCalc">Solar Calc</p> */}
          </div>
        </Link>

        <div className="d-flex align-items-center">
          {/* Language Selector */}
          <div className="me-3">
            <LanguageSelector />
          </div>

          {/* User Profile Dropdown */}
          <Dropdown>
            <div className="d-sm-flex align-items-center d-none">
              <p className="myProfile">{auth.currentUser?.displayName}</p>
              <Dropdown.Toggle className="imgDropdown" variant="none">
                <Image
                  className="mx-2"
                  src={`${auth.currentUser?.photoURL}`}
                  roundedCircle
                  width={40}
                  alt={t('navbar.userIconAlt')}
                />
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="mt-3 p-2">
              <Dropdown.Item className="navDropDown d-flex align-items-center">
                <Link
                  to="/profilesetting"
                  className="text-dark text-decoration-none d-flex align-items-center"
                >
                  <Image width={20} src={images.SettingIcon} />
                  <span className="mx-2">{t('navbar.profile')}</span>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="navDropDown mt-2">
                <Link
                  to="/security"
                  className="text-dark text-decoration-none d-flex align-items-center"
                >
                  <Image width={20} src={images.Security} />
                  <span className="mx-2">{t('navbar.security')}</span>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="navDropDown mt-2">
                <Link
                  to="/biling"
                  className="text-dark text-decoration-none d-flex align-items-center"
                >
                  <Image width={20} src={images.Billing} />
                  <span className="mx-2">{t('navbar.billing')}</span>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleLogout}
                className="navDropDown d-flex align-items-center mt-2"
              >
                <Image width={20} src={images.Logout} />
                <span className="mx-2">{t('navbar.logout')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="d-sm-none me-3 mt-2">
          <Image
            className="mb-2 cursor-pointer"
            src={images.HamburgerIcon}
            roundedCircle
            width={20}
            alt="menu"
          />
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Navbar;
/**
    * @description      : Internationalized Archive Card Component
    * @author           : 
    * @group            : 
    * @created          : 25/02/2025 - 11:44:29
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/02/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useState, useEffect } from "react";
import { Button, DropdownItem, DropdownMenu, Dropdown, DropdownToggle, Modal } from "react-bootstrap";
import images from "../../images/index";
import { Image } from "react-bootstrap";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { RxCaretDown } from "react-icons/rx";
import { auth } from "../../firebase";
import IconWrapper from "../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';

interface ArchieveCardProps {
  data?: any;
  onStatusChange?: any;
}

const ArchieveCard: React.FC<ArchieveCardProps> = ({
  data,
  onStatusChange,
}) => {
  const { t } = useTranslation(['archiveCards', 'common']);
  const navigate = useNavigate();
  const [reportList, setReportList] = useState<any[]>([]);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Move project to the active
  const moveToActive = async () => {
    setShowRestoreModal(false);
    const projectRef = doc(db, "projects", data.id);

    await updateDoc(projectRef, {
      status: "active",
    });
    onStatusChange();
  };

  // Move project to the trash
  const moveToTrash = async () => {
    setShowDeleteModal(false);
    const projectRef = doc(db, "projects", data.id);

    await updateDoc(projectRef, {
      status: "trash",
    });
    onStatusChange();
  };

  // Duplicate project
  const duplicateProject = async () => {
    try {
      // Get the copy text in the current UI language
      const copySuffix = t('common:copySuffix', "(Copy)");
      
      await addDoc(collection(db, "projects"), {
        ...data,
        dublicated: true,
        projectName: `${data.projectName} ${copySuffix}`,
      });
      onStatusChange();
    } catch (error) {
      console.error("Error duplicating project:", error);
    }
  };

  useEffect(() => {
    const getReportList = async () => {
      if (auth.currentUser && data.id) {
        const q = query(
          collection(db, "paidReport"),
          where("userId", "==", auth.currentUser?.uid),
          where("projectId", "==", data.id)
        );
        const querySnapshot = await getDocs(q);
        const newData = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setReportList(newData);
      }
    };

    getReportList();
  }, [data.id]);

  return (
    <React.Fragment>
      {data.id && (
        <div className="bg-light activeCard p-2">
          <div className="d-flex justify-content-between align-items-center">
            <p className="projectName">{data.projectName}</p>
            <Dropdown>
              <Dropdown.Toggle
                className="activeDropdown p-0 mb-2"
                variant="none"
              >
                <Image
                  className="mb-1"
                  src={images.SettingIcon}
                  alt="setting_option"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={duplicateProject}
                  className="d-flex align-items-center activeDropDownItem"
                >
                  <Image width={20} src={images.DuplicateIcon} />
                  <span className="mx-2">{t('common:actions.duplicate')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setShowRestoreModal(true)}
                  className="d-flex align-items-center activeDropDownItem"
                >
                  <Image width={20} src={images.ActiveIcon} />
                  <span className="mx-2">{t('archiveCards:buttons.restore')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setShowDeleteModal(true)}
                  className="d-flex align-items-center activeDropDownItem"
                >
                  <Image width={20} src={images.TrashIcon} />
                  <span className="mx-2">{t('archiveCards:buttons.delete')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Image
            src={data.projectPhoto}
            style={{ width: "100%", height: "156px", objectFit: "contain" }}
            alt="projectImg"
          />
          <div className="d-block d-md-flex justify-content-between align-items-center mt-2">
            <Dropdown>
              <DropdownToggle variant="none" className="m-0 p-0 border-0">
                <Button className="mt-2 activeCalculated d-flex justify-content-center align-items-center">
                  {reportList.length > 0 ? (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <img
                        src={images.Checkedicon}
                        width={20}
                        height={20}
                        style={{ objectFit: "contain" }}
                      />
                      <span> {t('common:results')} </span>
                      <IconWrapper
                        Icon={RxCaretDown}
                        style={{ marginTop: "1px" }}
                        size={25}
                        color="#000"
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <img
                        src={images.CrossIcon}
                        width={20}
                        height={20}
                        style={{ objectFit: "contain" }}
                      />
                      <span>{t('common:noResults')} </span>
                    </div>
                  )}
                </Button>
              </DropdownToggle>
              {reportList.length > 0 && (
                <DropdownMenu className="reportListDropdownMenu p-3 mt-1">
                  {reportList.map((value) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          if (value.paymentStatus) {
                            navigate("/paidproject", {
                              state: value.id,
                            });
                          } else {
                            navigate("/unpaidproject", {
                              state: { projectId: value.id },
                            });
                          }
                        }}
                        key={value.id}
                        className="py-3 px-2 mx-0 mb-3 reportListDropdownList"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0 p-0"> Id : {value.id} </p>
                          <p style={{ color: "#0984e3" }} className="m-0 p-0">
                            {" "}
                            {value.paymentStatus ? t('common:paid') : t('common:free')}{" "}
                          </p>
                        </div>
                        <hr style={{ border: "1px solid #000" }} />
                        <p> {t('common:createdAt')} {value.date}</p>
                        <p>
                          {" "}
                          {value.verticalPVArea + value.tiltedPVArea} {t('common:pvAreas')}
                        </p>
                        <p> {value.detectionPoints} {t('common:detectionPoints')}</p>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              )}
            </Dropdown>
            <Button
              className="mt-2 activeOPenProjects px-2 py-0"
              onClick={() => {
                navigate(`/map_page`, { state: data.id });
              }}
            >
              {t('activeCards:buttons.view')}
            </Button>
          </div>
        </div>
      )}

      {/* Restore Confirmation Modal */}
      <Modal show={showRestoreModal} onHide={() => setShowRestoreModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('archiveCards:modals.restore.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('archiveCards:modals.restore.message')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRestoreModal(false)}>
            {t('archiveCards:modals.restore.cancel')}
          </Button>
          <Button variant="primary" onClick={moveToActive}>
            {t('archiveCards:modals.restore.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('archiveCards:modals.delete.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('archiveCards:modals.delete.message')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            {t('archiveCards:modals.delete.cancel')}
          </Button>
          <Button variant="danger" onClick={moveToTrash}>
            {t('archiveCards:modals.delete.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ArchieveCard;
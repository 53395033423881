/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 01/04/2025 - 01:19:28
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 01/04/2025
    * - Author          : 
    * - Modification    : 
**/
import ChevronDown from "./tabler_chevron-down.png";
import leftArrow from "./ion_arrow-back.png";
import SearchIcon from './octicon_search-24.png';
import ProjectIcon from "./carbon_new-tab.png";
import infoIcon from "./octicon_info-24.png";
import activeProjects from "./icon-park-outline_rotating-forward.png";
import DeleteIcon from "./mi_delete.png";
import ArchiveIcon from "./mynaui_archive.png";
import pdfIcon from "./carbon_document-pdf.png";
import Image1 from "./image 7.png";
import FacebookIcon from "./logos_facebook.png";
import AddDetectionPoint from "./Add Detection Points Vector.png";
import AddTiledPvArea from "./Add Tilted PV Area Vector.png";
import AddFacadePoint from "./Add Facade PV Area Vector.png";
import TooltipImg_1 from "./tiltedTooltip_1.png";
import TooltipImg_2 from "./tiltedTooltip_2.png";
import VerticalTooltipImg_1 from "./verticalTooltip.png";
import Logo from "./newLogo.png";
import DetectionPointIcon from "./bxs_map-pin.png";
import TiltedPVAreaImage from "./tabler_solar-panel.png";
import VerticalPVAreaImage from "./Union.png";
import GoToPvArea from "./Add Location Vector.png";
import Image_1 from "./image 3.png";
import DuplicateIcon from "./ion_duplicate-outline.png";
import TrashIcon from "./mi_delete.png";
import ArchieveIcon from "./mynaui_archive.png";
import Checkedicon from "./icons8_checked.png";
import CrossIcon from "./iconoir_xbox-x.png";
import SettingIcon from "./solar_settings-linear.png";
import ActiveIcon from "./mdi_folder-move-outline.png";
import ShareIcon from "./solar_share-linear.png";
import FluentBoxImage from "./fluent_box-24-regular.png";
import ImageRec1 from "./Rectangle 2081.png"
import LeftArrow from "./tabler_chevron-left.png"
import RightArrow from "./tabler_chevron-left (1).png"
import ClientImage from "./Rectangle 2082.png"
import GoogleIcon from "./flat-color-icons_google.png";
import NavLogo from "./logo.svg";
import PriceImage from "./15€.png"
import CheckImage from "./fluent_checkmark-16-filled.png";
import Rectangle2065 from "./Rectangle 2065.png";
import Rectangle2067 from "./Rectangle 2067.png";
import LeftArrow1 from "./uiw_right.png";
import RightArrow2 from "./uiw_right (1).png";
import HamburgerIcon from "./hamburgerIcon.png";
import Logout from "./logout.png";
import Security from "./security.png";
import Billing from "./biling.png";
import FooterIcon from "./image 3 (1).png";
import Server from './serverDown.jpeg'

const images = {
    Logo,
    DuplicateIcon,
    SettingIcon,
    ActiveIcon,
    PriceImage,
    FooterIcon,
    HamburgerIcon,
    Logout,
    Security,
    Billing,
    CheckImage,
    Rectangle2065,
    Rectangle2067,
    LeftArrow1,
    RightArrow2,
    NavLogo,
    ShareIcon,
    GoogleIcon,
    FluentBoxImage,
    ImageRec1,
    LeftArrow,
    RightArrow,
    ClientImage,
    TrashIcon,
    ArchieveIcon,
    Checkedicon,
    CrossIcon,
    ChevronDown,
    leftArrow,
    SearchIcon,
    ProjectIcon,
    infoIcon,
    activeProjects,
    DeleteIcon,
    ArchiveIcon,
    pdfIcon,
    Image1,
    FacebookIcon,
    AddDetectionPoint,
    AddTiledPvArea,
    AddFacadePoint,
    TooltipImg_1,
    TooltipImg_2,
    VerticalTooltipImg_1,
    DetectionPointIcon,
    TiltedPVAreaImage,
    VerticalPVAreaImage,
    GoToPvArea,
    Image_1,
    Server

}

export default images;
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/03/2025 - 17:29:58
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/03/2025
    * - Author          : 
    * - Modification    : 
**/


import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  deleteDetectionChecked,
  deletedetectionPointsHeight,
  removeDetectionPoint,
  setDetectionElevationCheck,
  setdetectionPointsHeight,
  setTilEdgeCheck,
} from "../../../../store/reducers/bmapSlice";
import images from "../../../../images/index";
import { api } from "../../../../api/data";
import { FaRegEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { IoInformationCircleOutline } from "react-icons/io5";
import IconWrapper from "../../../../utils/IconWrapper";
// import "./DetectionPvAreaPage.css"; // Import the custom CSS file
import { useTranslation } from 'react-i18next';

interface Location {
  lat: number;
  lng: number;
}
interface ResultItem {
  elevation: number;
  location: Location;
  resolution: number;
}
interface DataPoint {
  results: ResultItem[];
  status: string;
}

const DetectionPvAreaPage: React.FC<{ projectName: string }> = ({
  projectName,
}) => {
  const dispatch = useDispatch();
  const [isDropdownExpanded, setIsDropdownExpanded] = useState<boolean[]>([]);
  const [detectionPointLength, setdetectionPointLength] = useState<number>(0);
  const [elevationPoints, setelevationPoints] = useState<{ results: any }[][]>(
    []
  );
  const [apiElevations, setApiElevations] = useState<{[key: number]: number}>({});
  const [manualElevations, setManualElevations] = useState<(number | null)[]>([]);
  const checkedElevation = useSelector(
    (state: RootState) => state.bmap.detectionElevationChecked
  );
  const detectionPoints = useSelector(
    (state: RootState) => state.bmap.detectionPoints
  );
  const { t } = useTranslation(['detectionPvArea', 'common']);

  useEffect(() => {
    if (detectionPoints.length > 0) {
      const newIndex = detectionPoints.length - 1;
      if (checkedElevation[newIndex] === undefined) {
        dispatch(
          setDetectionElevationCheck({
            index: newIndex,
            check: true,
          })
        );
      }
    }
  }, [detectionPoints.length]);




  const selectedAreaType = useSelector(
    (state: RootState) => state.bmap.selectedArea
  );
  const detectionPointHeight = useSelector(
    (state: RootState) => state.bmap.detectionPointsHeight
  );
  const checkedIndex = useSelector(
    (state: RootState) => state.bmap.tiltedEdgeChecked
  );

  // A more robust updateLocalStorage function
  const updateLocalStorage = (index: number, newValue: number) => {
    const storedData = localStorage.getItem("detectionPoint");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Ensure the structure exists before updating
      if (!parsedData[index]) {
        parsedData[index] = [{ results: [{ elevation: newValue }] }];
      } else if (!parsedData[index][0]) {
        parsedData[index][0] = { results: [{ elevation: newValue }] };
      } else if (!parsedData[index][0].results) {
        parsedData[index][0].results = [{ elevation: newValue }];
      } else if (!parsedData[index][0].results[0]) {
        parsedData[index][0].results[0] = { elevation: newValue };
      } else {
        parsedData[index][0].results[0].elevation = newValue;
      }
      localStorage.setItem("detectionPoint", JSON.stringify(parsedData));
    }
  };

  
    const fetchData = async () => {
      try {
        // Start with a clean slate for elevation points based on current detection points
        const updatedElevationPoints: any[] = [];
        const newApiElevations = {...apiElevations};
        
        for (let i = 0; i < detectionPoints.flat().length; i++) {
          const point = detectionPoints.flat()[i];
          const storedData = localStorage.getItem("detectionPoint");
          const storedElevations = storedData ? JSON.parse(storedData) : [];
          
          // Check if we should use stored data or fetch new data
          if (storedElevations[i] && !checkedElevation[i]) {
            updatedElevationPoints[i] = storedElevations[i];
          } else {
            // Fetch new elevation data
            const { lat, lng } = point;
            const res = await api.getElevation(lat, lng);
            updatedElevationPoints[i] = res.data;
          }
          
          // Update API elevations state
          if (updatedElevationPoints[i][0]?.results[0]?.elevation !== undefined) {
            newApiElevations[i] = updatedElevationPoints[i][0].results[0].elevation;
          }
        }
        
        // Update state with new data
        setelevationPoints(updatedElevationPoints);
        setApiElevations(newApiElevations);
        
        // Update localStorage with fresh data
        localStorage.setItem("detectionPoint", JSON.stringify(updatedElevationPoints));
      } catch (error) {
        console.error("Error fetching elevation data:", error);
      }
    };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const delayedFetch = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        fetchData();
      }, 1000); // 1 second delay
    };

    delayedFetch(); 

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [detectionPoints]);


  const handleDeleteDectectionPoint = (index: number) => {
    dispatch(removeDetectionPoint(index));
    dispatch(deletedetectionPointsHeight({ index }));
    dispatch(deleteDetectionChecked({ index }));
    
    // Update manual elevations state
    setManualElevations(prev => {
      const newValues = [...prev];
      newValues.splice(index, 1);
      return newValues;
    });
    
    // Update API elevations state
    setApiElevations(prev => {
      const newValues = {...prev};
      
      // Shift all indices after the deleted index
      for (let i = index; i < Object.keys(newValues).length; i++) {
        if (newValues[i + 1] !== undefined) {
          newValues[i] = newValues[i + 1];
        }
      }
      
      // Remove the last item
      delete newValues[Object.keys(newValues).length - 1];
      return newValues;
    });


    const storedData = localStorage.getItem("detectionPoint");
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    parsedData.splice(index, 1);
    localStorage.setItem("detectionPoint", JSON.stringify(parsedData));
  }
  };

  const toggleDropdown = (index: number) => {
    const newDropdownState = [...isDropdownExpanded];
    newDropdownState[index] = !newDropdownState[index];
    setIsDropdownExpanded(newDropdownState);
  };
  useEffect(() => {
    if (
      detectionPoints.length > 0 &&
      detectionPoints.length >= detectionPointLength
    ) {
      setIsDropdownExpanded((prev) => [
        ...new Array(detectionPoints.length - 1).fill(false),
        true,
      ]);
      setdetectionPointLength(detectionPoints.length);
    }
  }, [detectionPoints]);

   const groundElevationTooltip = (
    <Tooltip id="tooltip">
      <small>
        {t('detectionPvArea:tooltips.groundElevation')}
      </small>
    </Tooltip>
  );


  const renderCheckboxSection = (index: number) => (
    <div className="d-flex align-items-center ps-2">
      <Form.Check
        checked={checkedElevation[index] || false}
        onChange={(e) => {
          dispatch(
            setDetectionElevationCheck({
              index,
              check: e.target.checked,
            })
          );
          
          if (!e.target.checked && apiElevations[index] !== undefined) {
            const value = apiElevations[index];
            // Format to 1 decimal place and convert back to number
            const formattedValue = Math.round(value * 10) / 10;
            
            setManualElevations(prev => {
              const newValues = [...prev];
              newValues[index] = formattedValue;
              return newValues;
            });
            
            updateLocalStorage(index, formattedValue);
          }
        }}
      />
      <label
        className="ms-2"
        style={{ fontSize: "12px", color: "#858585" }}
      >
        {t('detectionPvArea:fields.autoCalculate')}
      </label>
    </div>
  );
  

  
  // Updated renderElevationSection to format values correctly
  const renderElevationSection = (index: number) => (
    <div className="d-flex gap-1 ps-2">
      <div className="coolinput">
        <label htmlFor="input" className="text">
          {t('detectionPvArea:fields.groundElevation')}
        </label>
        <input
          type="number"
          step="0.1"
          placeholder={t('detectionPvArea:fields.elevationPlaceholder')}
          name="input"
          className={checkedElevation[index] ? "input disabledInput" : "input"}
          disabled={checkedElevation[index]}
          value={
              checkedElevation[index]
                ? elevationPoints[index]
                  ? (elevationPoints[index][0]?.results[0]?.elevation || 0).toFixed(1)
                  : t('detectionPvArea:fields.calculating')
                : manualElevations[index] !== undefined && manualElevations[index] !== null
                  ? Number(manualElevations[index]).toFixed(1)
                  : apiElevations[index] !== undefined
                    ? apiElevations[index].toFixed(1)
                    : ""
            }
          onChange={(e) => {
            if (!checkedElevation[index]) {
              const value = e.target.value;
              const numValue = value === "" ? null : Number(value);
              setManualElevations(prev => {
                const newValues = [...prev];
                newValues[index] = numValue;
                return newValues;
              });
              
              if (numValue !== null) {
                updateLocalStorage(index, numValue);
              }
            }
          }}
        />
      </div>
      <OverlayTrigger
        placement="top"
        overlay={groundElevationTooltip}
      >
        <Button variant="none" className="mt-2 p-0">
        <IconWrapper Icon={IoInformationCircleOutline} 
                         color="#0984E3"
                         size={20}
                         className="mt-3"/>
        </Button>
      </OverlayTrigger>
    </div>
  );


  
  return (
    <div>
      {detectionPoints.length < 1 &&
        (selectedAreaType?.value === "detection" ||
          selectedAreaType?.value === "detectionTab") && (
          <div className="detectionNothingToShow">
            <div>
              <Image
                src={images.AddDetectionPoint}
                alt="addTiltedPVarea"
                loading="lazy"
              />
              <strong>{t('detectionPvArea:emptyState.title')}</strong>
              <small>
                {t('detectionPvArea:emptyState.description')}
              </small>
            </div>
          </div>
        )}
      {detectionPoints.length > 0 &&
        (selectedAreaType?.value === "detection" ||
          selectedAreaType?.value === "detectionTab") && (
          <div style={{ height: "76vh", overflowY: "auto" }}>
            {detectionPoints.map((value: any, index: number) => (
              <div
                className="DetectionPvAreaPage mb-2 w-100"
                key={`detectionPoints${index}`}
              >
                <Accordion
                  className="w-100"
                  activeKey={isDropdownExpanded[index] ? `${index}` : null}
                >
                  <Accordion.Item
                    eventKey={`${index}`}
                    style={{ width: "100%" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleDropdown(index)}
                      style={{ width: "100%" }}
                    >
                      <div className="projectName">
                        {`${t('detectionPvArea:card.prefix')} - ${index + 1}`} &nbsp; |
                        <IconWrapper Icon={MdDeleteOutline}   size={17}
                          className="mb-1"
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion from toggling
                            handleDeleteDectectionPoint(index);
                          }}/>
                      </div>
                      <div className={`collapseBtn`}>
                        {isDropdownExpanded[index] ? (
                          <span>
                            {t('detectionPvArea:card.collapse')} <IconWrapper Icon={RxCaretUp} size={20}/>
                          </span>
                        ) : (
                          <span>
                            {t('detectionPvArea:card.expand')} <IconWrapper Icon={RxCaretDown} size={20}/>
                          </span>
                        )}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">{t('detectionPvArea:sections.altitudeParameters')}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="coolinput">
                          <label htmlFor="input" className="text">
                            {t('detectionPvArea:fields.aboveGround')}
                          </label>
                          <input
                            id={`${index}`}
                            type="number"
                            step="0.01"
                            placeholder={t('detectionPvArea:fields.groundLevelPlaceholder')}
                            name="input"
                            className="input"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                const nextIndex =
                                  index === detectionPoints.length - 1
                                    ? 0
                                    : index + 1;
                                const nextInput = document.getElementById(
                                  `${nextIndex}`
                                );

                                if (!isDropdownExpanded[nextIndex]) {
                                  toggleDropdown(nextIndex);
                                  setTimeout(() => {
                                    nextInput?.focus();
                                  }, 300);
                                } else {
                                  nextInput?.focus();
                                }
                              }
                            }}
                            value={detectionPointHeight[index] || ""}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (!isNaN(value)) {
                                dispatch(
                                  setdetectionPointsHeight({
                                    index,
                                    value,
                                  })
                                );
                              } else {
                                dispatch(
                                  setdetectionPointsHeight({
                                    index,
                                    value: null,
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column">
                        {renderElevationSection(index)}
                        {renderCheckboxSection(index)}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default DetectionPvAreaPage;
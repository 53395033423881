/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 14/01/2025 - 16:29:48
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 14/01/2025
    * - Author          : 
    * - Modification    : 
**/
// UTCModal.tsx
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { getDocumentById, updateDocument } from "../../../utils/firebaseHandlers";
import { toast } from "react-toastify";
import IconWrapper from "../../../utils/IconWrapper";

type Props = {
  show: boolean;
  closeModal: () => void;
  projectID:string
};

const timezones = [
    "UTC-12 (BIT, IDLW)",
    "UTC-11 (NUT, SST)",
    "UTC-10 (CKT, HST, TAHT)",
    "UTC-9 (AKST, GIT)",
    "UTC-8 (CIST, PST)",
    "UTC-7 (MST)",
    "UTC-6 (CST, GALT)",
    "UTC-5 (EST, ACT, COT, EASST, ECT, PET)",
    "UTC-4 (AMT, AST, BOT, CLT, COST, ECT, FKT, GYT, PYT, VET)",
    "UTC-3 (AMST, ART, BRT, CLST, FKST, GFT, PMST, UYT)",
    "UTC-2 (BRST, GST, UYST)",
    "UTC-1 (AZOT, CVT, EGT)",
    "UTC+0 (GMT, WET)",
    "UTC+1 (CET, WAT)",
    "UTC+2 (CAT, EET, SAST)",
    "UTC+3 (AST, EAT, IOT, MSK, TRT)",
    "UTC+4 (AMT, AZT, GET, GST, SAMT, SCT)",
    "UTC+5 (AQTT, HMT, MVT, PKT, TJT, TMT, UZT)",
    "UTC+6 (BIOT, BST, BTT, KGT, OMST)",
    "UTC+7 (CXT, DAVT, ICT, THA, WIT)",
    "UTC+8 (AWST, CIT, CT, HKT, MYT, PHT, SGT)",
    "UTC+9 (EIT, JST, KST)",
    "UTC+10 (AEST, PGT, VLAT)",
    "UTC+11 (NCT, NFT, PONT, SBT)",
    "UTC+12 (FJT, GILT, MHT, NZST, WAKT)",
    "UTC+13 (PHOT, TOT)",
    "UTC+14 (LINT)",
  ];

const UTCModal: React.FC<Props> = ({ show, closeModal, projectID }) => {
const [timeZone,setTimeZone] =  useState<string>("")

// to get the already selected timezone that user set in firebase
useEffect(()=>{
    getDocumentById("projects",projectID).then((data)=>{
        setTimeZone(data?.timeZone) 
    })
   },[projectID])
// to change the utc value in fire base
const handleInputChange = async()=>{
    updateDocument("projects",projectID,{timeZone})
    .then(()=>{toast.success("UTC change successfully!")})
    .catch(()=>{toast.error("Error in changint UTC!")}) 
    closeModal()   
}

  return (
    <Modal size="sm" className="mt-5 pt-5" show={show} onHide={closeModal}>
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title id="example-modal-sizes-title-sm"> Change UTC </Modal.Title>
        <IconWrapper Icon={IoClose} onClick={closeModal} size={25} className="mt-1" style={{ cursor: "pointer" }} />
      </Modal.Header>
      <Modal.Body className="w-100" style={{display:'flex',placeItems:'center',flexDirection:'column',gap:"20px"}}>
      <select
        style={{ fontFamily: "lato", height: "44px", borderRadius: "4px", border: "0.5px solid #858585" }}
        className="ps-2 w-100 mt-2" value={timeZone} onChange={(e)=>{setTimeZone(e.target.value)}} >
        {timezones.map((zone:string) => (
            <option style={{ fontFamily: "lato" }} key={zone} value={zone}>{zone}</option>
        ))}
      </select>
      <Button
        style={{backgroundColor:'#599afc',height:"32px",width:"150px",color:"#fff",textAlign:"center",lineHeight:0,margin:'auto'}}
        variant="none" onClick={handleInputChange}> Change UTC 
      </Button>
    </Modal.Body>
    </Modal>
  );
};

export default UTCModal;
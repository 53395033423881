/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 12/02/2025 - 16:22:52
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 12/02/2025
    * - Author          : 
    * - Modification    : 
**/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
// import { CalculateGlare as glareStatus } from "../../../store/reducers/bmapSlice";
import { toast } from "react-toastify";
// import moment from "moment-timezone";
import { auth, db } from "../../../firebase";
import {
  doc,
  setDoc,
  updateDoc,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { postData } from "../../../utils/axios";
import IconWrapper from "../../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';
 
interface PolygonPoint {
  lat: number;
  lon: number;
}

interface PolygonData {
  point1: PolygonPoint;
  point2: PolygonPoint;
  point3: PolygonPoint;
  point4: PolygonPoint;
}

interface TiltedPolygonItem {
  polygonData: PolygonData;
  // Add other properties as needed
}

interface VerticalEdge {
  lat: number;
  lon: number;
  elevation?: number;
}

interface VerticalPolygonData {
  upperEdge: VerticalEdge;
  lowerEdge: VerticalEdge;
}

interface VerticalPolygonItem {
  polygonData: VerticalPolygonData;
  // Add other properties as needed
}

interface DetectionPointResult {
  location: {
    lat: number;
    lng: number;
  };
  elevation: number;
}

interface DetectionPointItem {
  results: DetectionPointResult[];
}

interface SimulationParameter {
  grid_width: string | number;
  resolution: string | number;
  sun_elevation_threshold: string | number;
  beam_spread: string | number;
  sun_angle: string | number;
  sun_reflection_threshold: string | number;
  intensity_threshold: string | number;
  moduleType: string | number;
}

interface ExcludeAreaPoint {
  lat: number;
  lng: number;
}


const CalculateGlare: React.FC = () => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tiltedPolygons = useSelector(
    (state: RootState) => state.bmap.tiltedPolygons
  );
  const tiltedIntersection = useSelector(
    (state: RootState) => state.bmap.tiltedIntersection
  );

  const verticalPolygon = useSelector(
    (state: RootState) => state.bmap.verticalPolygons
  );
  const tiltedUpperHeight = useSelector(
    (state: RootState) => state.bmap.tiltedupperHeights
  );
  const tiltedLowerHeight = useSelector(
    (state: RootState) => state.bmap.tiltedlowerHeights
  );
  const tiltedAzimuth = useSelector(
    (state: RootState) => state.bmap.tiltedAzimuthValue
  );
  const polygonUpperHeight = useSelector(
    (state: RootState) => state.bmap.tiltedupperHeights
  );
  const polygonLowerHeight = useSelector(
    (state: RootState) => state.bmap.tiltedlowerHeights
  );
  const detectionPoint = useSelector(
    (state: RootState) => state.bmap.detectionPoints
  );
  const detectionPointHeight = useSelector(
    (state: RootState) => state.bmap.detectionPointsHeight
  );
  const verticalSlope = useSelector(
    (state: RootState) => state.bmap.verticalSlopeArea
  );
  const verticalLowerHeight = useSelector(
    (state: RootState) => state.bmap.verticalLowerHeight
  );
  const verticalUpperHeight = useSelector(
    (state: RootState) => state.bmap.verticalUpperHeight
  );
  const { t } = useTranslation(['mapPage', 'common']);



  // console.log("verticalUpperHeight",verticalUpperHeight)
  
  const projectName = localStorage.getItem("projectName");
  const excludeArea = useSelector((state: RootState) => state.bmap.excludeArea);
  const Slope = useSelector((state: RootState) => state.bmap.slopeArea);
  const zoomLevel = useSelector((state: RootState) => state.bmap.zoom);
  const rulerLine = useSelector((state: RootState) => state.bmap.rulerLines);
  const center = useSelector((state: RootState) => state.bmap.center);
  const verticalAvgElevation = useSelector(
    (state: RootState) => state.bmap.verticalAverageElevation
  );
  const tiltedAvgElevation = useSelector(
    (state: RootState) => state.bmap.tiltedAverageElevation
  );

  const verticalAzimuth = useSelector(
    (state: RootState) => state.bmap.verticalAzimuthValue
  );
  const simulationParameter = useSelector(
    (state: RootState) => state.bmap.simulationParameter
  );
  const tiltedCheckedElevation = useSelector(
    (state: RootState) => state.bmap.tiltedElevationChecked
  );
  const tiltedCheckedAzimuth = useSelector(
    (state: RootState) => state.bmap.tiltedAzimuthChecked
  );
  const tiltedCheckedIndex = useSelector(
    (state: RootState) => state.bmap.tiltedEdgeChecked
  );
  const verticalCheckedAzimuth = useSelector(
    (state: RootState) => state.bmap.verticalAzimuthChecked
  );
  const verticalCheckedElevation = useSelector(
    (state: RootState) => state.bmap.verticalElevationChecked
  );
  const verticalSwap = useSelector(
    (state: RootState) => state.bmap.verticalSwap
  );
  const tiltedSwap = useSelector((state: RootState) => state.bmap.tiltedSwap);
  const generateRandomCombination = () => {
    const randomLetters = Array.from({ length: 7 }, () =>
      String.fromCharCode(Math.floor(Math.random() * 26) + 97)
    ).join("");
    const randomNumber = Math.floor(Math.random() * 100000);
    return randomLetters.toLocaleUpperCase() + randomNumber;
  };

  // const generateNineDigitNumber = () => {
  //   const min = 100000000;
  //   const max = 999999999;
  //   const nineDigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  //   return nineDigitNumber.toString();
  // };

  function getUtcOffset(timeZoneString: string) {
    const match = timeZoneString.match(/UTC([+-]\d+)/);
    return match ? match[1] : 0;
  }

  const date = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (verticalPolygon.length > 0) {
      // Store updated vertical polygons in local storage
      localStorage.setItem("AzimuthVerticalDataArray", JSON.stringify(verticalPolygon));
    } else {
      // Remove from local storage if empty
      localStorage.removeItem("AzimuthVerticalDataArray");
    }
  }, [verticalPolygon]);

  // console.log("verticalPolygonsssss",verticalPolygon)
  
  const detectionData = localStorage.getItem("detectionPoint");
  useEffect(() => {
    if (tiltedPolygons.length > 0) {
      localStorage.setItem("AzimuthDataArray", JSON.stringify(tiltedPolygons));
    } else {
      localStorage.removeItem("AzimuthDataArray");
    }
  }, [tiltedPolygons]);
  
  const formattedDateString = `${date.getDate()}. ${
    months[date.getMonth()]
  } ${date.getFullYear()} ${
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

  const storeDatainDB = async () => {
    const userDocRef = doc(db, "projects", location.state);
    await updateDoc(userDocRef, {
      calculated: true,
      detectionPoint: JSON.stringify(detectionPoint),
      detectionPointHeight,
      verticalPVArea: JSON.stringify(verticalPolygon),
      verticalLowerHeight,
      verticalUpperHeight,
      verticalSlope,
      tiltedPolygons: JSON.stringify(tiltedPolygons),
      tiltedUpperHeight: polygonUpperHeight,
      tiltedLowerHeight: polygonLowerHeight,
      tiltedSlope: Slope,
      excludeArea: JSON.stringify(excludeArea),
      rulerLine,
      tiltedCheckedElevation,
      tiltedCheckedIndex,
      tiltedCheckedAzimuth,
      verticalCheckedAzimuth,
      verticalCheckedElevation,
      mapCenter: center,
      verticalSwap,
      tiltedSwap,
      totalCalcualetdReports: increment(1),
    });
  };

  const startTiltedSimulation = async (): Promise<void> => {
    const simValue = generateRandomCombination();
    await storeDatainDB();
    try {
      const AzimuthDataArray = localStorage.getItem("AzimuthDataArray") || "";
      const data: TiltedPolygonItem[] = AzimuthDataArray ? JSON.parse(AzimuthDataArray) : [];
      console.log("data",data)
      const detectionData = localStorage.getItem("detectionPoint");
      const verticalDataArray = localStorage.getItem("AzimuthVerticalDataArray") || "";
      const verticalData: VerticalPolygonItem[] = verticalDataArray ? JSON.parse(verticalDataArray) : [];
  
      const detectionPointsData: DetectionPointItem[][] = detectionData ? JSON.parse(detectionData) : [];
      const utcRegion = localStorage.getItem("utcRegion");
      const utcOffset = utcRegion ? getUtcOffset(utcRegion) : 0;
      
      // Add validation to ensure we're only mapping valid data
      const tiltedPVAreas = data
        .filter((value: TiltedPolygonItem | undefined) => value && value.polygonData && 
                value.polygonData.point1 && value.polygonData.point2 && 
                value.polygonData.point3 && value.polygonData.point4)
        .map((value: TiltedPolygonItem, index: number) => [
          {
            latitude: Number((value.polygonData.point1.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.point1.lon || 0).toFixed(6)),
            ground_elevation: Number(tiltedAvgElevation[index] || 0),
            height_above_ground: Number(polygonUpperHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.point2.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.point2.lon || 0).toFixed(6)),
            ground_elevation: Number(tiltedAvgElevation[index] || 0),
            height_above_ground: Number(polygonUpperHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.point3.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.point3.lon || 0).toFixed(6)),
            ground_elevation: Number(tiltedAvgElevation[index] || 0),
            height_above_ground: Number(polygonLowerHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.point4.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.point4.lon || 0).toFixed(6)),
            ground_elevation: Number(tiltedAvgElevation[index] || 0),
            height_above_ground: Number(polygonLowerHeight[index] || 0),
          },
        ]);
  
      // Similar validation for vertical PV areas
      const verticalPVAreas = verticalData
        .filter((value: VerticalPolygonItem | undefined) => value && value.polygonData && 
                value.polygonData.upperEdge && value.polygonData.lowerEdge)
        .map((value: VerticalPolygonItem, index: number) => [
          {
            latitude: Number((value.polygonData.upperEdge?.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.upperEdge?.lon || 0).toFixed(6)),
            ground_elevation: Number(verticalAvgElevation[index] || 0),
            height_above_ground: Number(verticalUpperHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.lowerEdge?.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.lowerEdge?.lon || 0).toFixed(6)),
            ground_elevation: Number(verticalAvgElevation[index] || 0),
            height_above_ground: Number(verticalUpperHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.lowerEdge?.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.lowerEdge?.lon || 0).toFixed(6)),
            ground_elevation: Number(verticalAvgElevation[index] || 0),
            height_above_ground: Number(verticalLowerHeight[index] || 0),
          },
          {
            latitude: Number((value.polygonData.upperEdge?.lat || 0).toFixed(6)),
            longitude: Number((value.polygonData.upperEdge?.lon || 0).toFixed(6)),
            ground_elevation: Number(verticalAvgElevation[index] || 0),
            height_above_ground: Number(verticalLowerHeight[index] || 0),
          },
        ]);
  
      // Make sure tiltedPolygons is defined before mapping
      const listOfTiltedPvAreaInformation = Array.isArray(tiltedPolygons) 
        ? tiltedPolygons.map((value: any, index: number) => ({
            azimuth: Number(tiltedAzimuth[index] || 0),
            tilt: Number(Slope[index] || 0),
            name: `PV Area ${index + 1}`,
          }))
        : [];
  
      // Make sure verticalPolygon is defined before mapping
      const listOfVerticalPvAreaInformation = Array.isArray(verticalPolygon)
        ? verticalPolygon.map((value: any, index: number) => ({
            azimuth: Number(verticalAzimuth[index] || 0),
            tilt: Number(verticalSlope[index] || 0),
            name: `PV Area ${(Array.isArray(tiltedPolygons) ? tiltedPolygons.length : 0) + index + 1}`,
          }))
        : [];
  
      // Add validation for detection points
      const listOfOps = detectionPointsData
        .filter((detectionPoint: DetectionPointItem[] | undefined) => detectionPoint && 
                detectionPoint[0] && detectionPoint[0].results && 
                detectionPoint[0].results[0] && 
                detectionPoint[0].results[0].location)
        .map((detectionPoint: DetectionPointItem[], index: number) => ({
          latitude: Number((detectionPoint[0].results[0]?.location.lat || 0).toFixed(6)),
          longitude: Number((detectionPoint[0].results[0]?.location.lng || 0).toFixed(6)),
          ground_elevation: Number((detectionPoint[0].results[0]?.elevation || 0).toFixed(2)),
          height_above_ground: Number(detectionPointHeight[index] || 0),
        }));
  
      // Make sure excludeArea is defined and properly structured
      const excludedAreas = Array.isArray(excludeArea) 
        ? excludeArea
            .filter((innerArray: ExcludeAreaPoint[] | undefined) => Array.isArray(innerArray) && innerArray.length > 0)
            .map((innerArray: ExcludeAreaPoint[]) => {
              const firstElement = innerArray[0];
              const lastElement = innerArray[innerArray.length - 1];
              const adjustedArray = (firstElement && lastElement && 
                                    firstElement.lat === lastElement.lat && 
                                    firstElement.lng === lastElement.lng)
                ? innerArray.slice(0, -1) 
                : innerArray; 
              return adjustedArray
                .filter((value: ExcludeAreaPoint | undefined) => value && value.lat !== undefined && value.lng !== undefined)
                .map((value: ExcludeAreaPoint) => ({
                  latitude: value.lat,
                  longitude: value.lng,
                }));
            })
        : [];
      console.log("tiltedPVAreas",tiltedPVAreas)
      // Safely access project name and other properties
      const raw = JSON.stringify({
        identifier: simValue,
        pv_areas: [...tiltedPVAreas, ...verticalPVAreas],
        list_of_pv_area_information: [
          ...listOfTiltedPvAreaInformation,
          ...listOfVerticalPvAreaInformation,
        ],
        list_of_ops: listOfOps,
        excluded_areas: excludedAreas,
        meta_data: {
          project_name: projectName || "Unnamed Project",
          user_id: auth.currentUser?.uid || "",
          project_id: location.state || "",
          sim_id: simValue,
          timestamp: Math.floor(Date.now() / 1000),
          utc: Number(utcOffset),
        },
  
        simulation_parameter: {
          grid_width: parseFloat(((simulationParameter as SimulationParameter)?.grid_width || "0").toString().replace(',', '.')),
          resolution: `${(simulationParameter as SimulationParameter)?.resolution || "0"} min`,
          sun_elevation_threshold: parseFloat(((simulationParameter as SimulationParameter)?.sun_elevation_threshold || "0").toString().replace(',', '.')),
          beam_spread: parseFloat(((simulationParameter as SimulationParameter)?.beam_spread || "0").toString().replace(',', '.')),
          sun_angle: parseFloat(((simulationParameter as SimulationParameter)?.sun_angle || "0").toString().replace(',', '.')),
          sun_reflection_threshold: parseFloat(((simulationParameter as SimulationParameter)?.sun_reflection_threshold || "0").toString().replace(',', '.')),
          intensity_threshold: parseFloat(((simulationParameter as SimulationParameter)?.intensity_threshold || "0").toString().replace(',', '.')),
          module_type: parseFloat(((simulationParameter as SimulationParameter)?.moduleType || "0").toString().replace(',', '.')),
          zoom_level: zoomLevel || 0,
        },
      });
      console.log("raw",raw)
      await setDoc(
        doc(db, "paidReport", simValue),
        {
          userId: auth.currentUser?.uid || "",
          paymentStatus: false,
          projectName: projectName || "Unnamed Project",
          verticalPVArea: Array.isArray(verticalPolygon) ? verticalPolygon.length : 0,
          tiltedPVArea: Array.isArray(tiltedPolygons) ? tiltedPolygons.length : 0,
          detectionPoints: Array.isArray(detectionPoint) ? detectionPoint.length : 0,
          uploadState: 0,
          fileUrl: false,
          date: formattedDateString || new Date().toISOString(),
          projectId: location.state || "",
        },
        { merge: true }
      ).then(async () => {
        navigate("/projectOverview", { state: simValue });
        await postData<any, typeof raw>("/generate_reports", raw, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      });
    } catch (error) {
      console.error("Failed to Retrieve PDF:", error);
      toast.error("Failed to Retrieve PDF: " + (error instanceof Error ? error.message : String(error)));
      try {
        const docRef = doc(db, "paidReport", simValue);
        await deleteDoc(docRef);
      } catch (deleteError) {
        console.error("Error deleting document:", deleteError);
      }
      navigate(`/map_page`, { state: location.state });
    }
  };
  
  const isButtonDisabled =
    (tiltedPolygons.length === 0 && verticalPolygon.length === 0) ||
    detectionPoint.length === 0 ||
    tiltedIntersection;

  useEffect(() => {
    const handleButtonClick = () => {
      let allFieldsValid = true;
      const errors = [];

      if (tiltedIntersection) {
        setButtonDisabled(true);
        allFieldsValid = false;
        setErrorMessage("Tilted polygon is intersected the lines.");
      }

      if (tiltedPolygons.length === 0 && verticalPolygon.length === 0) {
        setButtonDisabled(true);
        allFieldsValid = false;
        setErrorMessage("You need to enter at least one PV area.");
      }

      if (detectionPoint.length === 0) {
        setButtonDisabled(true);
        allFieldsValid = false;
        setErrorMessage("You need to enter at least one detection point.");
      }

      if (verticalPolygon.length > 0) {
        verticalPolygon.forEach((value, index) => {
          if (verticalLowerHeight[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Vertical lower height of VPV-${index + 1} is required.`
            );
          }
          if (verticalUpperHeight[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Vertical upper height of VPV-${index + 1} is required.`
            );
          }
          if (verticalAzimuth[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Vertical azimuth of VPV-${index + 1} is required.`
            );
          }
          if (verticalSlope[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(`Tile angle of VPV-${index + 1} is required.`);
          }
          if (verticalAvgElevation[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Vertical average elevation of VPV-${index + 1} is required.`
            );
          }
        });
      }

      if (detectionPoint.length > 0) {
        detectionPoint.forEach((value, index) => {
          if (detectionPointHeight[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Detection point height of P-${index + 1} is required.`
            );
          }
        });
      }

      if (tiltedPolygons.length > 0) {
        tiltedPolygons.forEach((value, index) => {
          if (tiltedLowerHeight[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Tilted lower height of TPV-${index - 1} is required.`
            );
          }
          if (tiltedUpperHeight[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Tilted upper height of TPV-${index - 1} is required.`
            );
          }
          if (Slope[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(`Tile angle of TPV-${index - 1} is required.`);
          }
          if (tiltedAzimuth[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(`Tilted azimuth of TPV-${index - 1} is required.`);
          }
          if (tiltedAvgElevation[index] == null) {
            allFieldsValid = false;
            setButtonDisabled(true);
            setErrorMessage(
              `Tilted elevation of TPV-${index - 1} is required.`
            );
          }
        });
      }

      if (allFieldsValid) {
        setButtonDisabled(false);
      }
    };

    handleButtonClick();
  }, [
    tiltedPolygons,
    verticalPolygon,
    detectionPoint,
    verticalLowerHeight,
    verticalUpperHeight,
    verticalSlope,
    verticalAzimuth,
    tiltedLowerHeight,
    tiltedIntersection,
    tiltedUpperHeight,
    tiltedAzimuth,
    Slope,
    detectionPointHeight,
    verticalAvgElevation,
    tiltedAvgElevation,
  ]);
  return (
    <Button
      className={buttonDisabled ? "disabledCalculateButton" : "CalculateButton"}
      onClick={() => {
        if (buttonDisabled) {
          toast.error(errorMessage);
        } else {
          startTiltedSimulation();
        }
      }}
    >
         <IconWrapper Icon={FaPlay}   size={15} color="#fff" /> {t('mapPage:calculation.calculationglare')}
    </Button>
  );
};

export default CalculateGlare;
/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/03/2025 - 18:20:46
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import images from "../../images/index";
import { Link } from "react-router-dom";
import Navbar from "../../components/shared/navbar";
import Footer from "../../components/shared/footer";
import FullReport from "../../components/pages/poFullReport";
import { useLocation } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { GoArrowLeft } from "react-icons/go";
import IconWrapper from "../../utils/IconWrapper";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ReportCardProps {
  date: string;
  fileUrl: {
    paidReportUrl: string;
    reportUrl: string;
  };
  owner: string;
  paymentStatus: string;
  projectName: string;
  tiltedPVArea: number;
  verticalPVArea: number;
  detectionPoints: string;
  id: string;
  projectId: string;
  imageUrl: string;
}

const UnpaidProject: React.FC = () => {
  const { t } = useTranslation(['unpaidProject', 'common']);
  const [responceData, setresponceData] = useState<ReportCardProps | null>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();
  const docId = location.state.projectId;
  useEffect(() => {
    const docRef = doc(db, "paidReport", docId);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setresponceData(docSnap.data() as ReportCardProps);
          console.log(docSnap.data());
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error getting document:", error);
      }
    );

    return () => unsubscribe();
  }, [docId]);

  const handleBackToMap = () => {
    // First navigate
    navigate('/map_page', { state: responceData?.projectId });
    // Then refresh
    window.location.reload();
  };


  return (
    <React.Fragment>
      <Navbar />

      <div className="centerpart mt-3">
        <Link className="dashboard" to="/dashboard">
        <IconWrapper Icon={GoArrowLeft} />
          {t('common:navigation.dashboard')}
        </Link>
        <p className="m-0">{t('unpaidProject:header.activeProject')} / {responceData?.projectName}</p>
      </div>
      <div className="my-3 d-flex align-items-end">
      <div 
  className="mx-3 d-flex align-items-center" 
  onClick={handleBackToMap}
  style={{ cursor: "pointer", width: "fit-content", position: "absolute" }}
>
  <Image className="mb-3" src={images.leftArrow} alt="back_arrow" />
  <p className="d-none d-sm-inline mx-2 backToDashboard">
    {t('unpaidProject:backToMap')}
  </p>
</div>
        <h1 className="newProjects mx-auto">{t('unpaidProject:header.projectOverview')}</h1>
      </div>
      <Container fluid className="mt-4 mb-5 py-3">
        <Row className="justify-content-center gap-3">
          <Col md={12} lg={5} style={{ width: "500px" }}>
            <div className="d-flex justify-content-between align-items-center PO-MYHOME">
              <p className="mt-3 mb-0 unpaidtextOverFlow">
                {responceData?.projectName}
              </p>
              <p className="mt-3 mb-0">{t('unpaidProject:projectDetails.id')}: {docId}</p>
            </div>
            <hr />
            <div>
              <div className="d-flex justify-content-between">
                <p className="PO-date">{t('unpaidProject:projectDetails.date')}:</p>
                <p className="POdata">{responceData?.date}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="PO-date">{t('unpaidProject:projectDetails.pvAreas')}:</p>
                <p className="POdata">
                  {responceData?.verticalPVArea} {t('unpaidProject:projectDetails.vertical')},&nbsp;
                  {responceData?.tiltedPVArea} {t('unpaidProject:projectDetails.tilted')}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="PO-date">{t('unpaidProject:projectDetails.detectionPoints')}:</p>
                <p className="POdata">
                  {responceData?.detectionPoints} {t('unpaidProject:projectDetails.detectionPointsLabel')}
                </p>
              </div>
            </div>
            <a className="paid-downloadPDF" href={responceData?.fileUrl.reportUrl} target="_blank" download="docId">
                {t('unpaidProject:buttons.downloadPdf')}
            </a>
            <div className="d-flex justify-content-center">
              <iframe
                src={responceData?.fileUrl?.reportUrl}
                style={{
                  width: `700px`,
                  height: "800px",
                }}
              />
            </div>
          </Col>
          <Col md={12} lg={3}>
            <FullReport />
          </Col>
        </Row>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default UnpaidProject;
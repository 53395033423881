/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 16:55:22
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/
import React, { useEffect } from "react";
import Footer from "../../components/shared/footer";
import Navbar from "../../components/shared/navbar";
import Container from "react-bootstrap/Container";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import images from '../../images/index';
import { Button } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { auth } from "../../firebase";
import { applyActionCode } from "firebase/auth";
import { useTranslation } from 'react-i18next';

const Dashboard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  const { t } = useTranslation(['dashboard', 'common']);
  
  useEffect(() => {
    const verifyEmail = async () => {
      if (oobCode) {
        try {
          await applyActionCode(auth, oobCode);
          // auth?.currentUser?.reload();
        } catch (error) {
          console.error("Error verifying email:", error);
        }
      }
    };

    verifyEmail();
  }, [oobCode]);

  const addProjectTooltip = (
    <Tooltip id="tooltip">
      <small>{t('dashboard:tooltips.newProject')}</small>
    </Tooltip>
  );

  const ActiveProjectTooltip = (
    <Tooltip id="tooltip">
      <small>{t('dashboard:tooltips.activeProjects')}</small>
    </Tooltip>
  );

  const ArchiveTooltip = (
    <Tooltip id="tooltip">
      <small>{t('dashboard:tooltips.archive')}</small>
    </Tooltip>
  );

  const trashTooltip = (
    <Tooltip id="tooltip">
      <small>{t('dashboard:tooltips.trash')}</small>
    </Tooltip>
  );

  const ReportTooltip = (
    <Tooltip id="tooltip">
      <small>{t('dashboard:tooltips.reports')}</small>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Navbar />
      <div className="dashboardDiv py-4">
        <h1 className="dashHeading mt-3 mx-5">{t('dashboard:title')}</h1>
        <Container>
          <div className="d-flex text-center justify-content-center align-items-center flex-column flex-lg-row">
            {/* New Project Card */}
            <div className="px-2 mt-4 mx-4 bg-light d-flex align-items-center dashCard justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="mx-1"
                  src={images.ProjectIcon}
                  alt={t('dashboard:newProject.altText')}
                  width={24}
                />
                <p className="mx-2 my-1 cardText">{t('dashboard:newProject.title')}</p>
              </div>
              <div className="mt-2">
                <Link to="/newproject" className="text-decoration-none">
                  <Button variant="none" className="d-inline mx-2 dashButton">
                    {t('dashboard:newProject.buttonText')}
                  </Button>
                </Link>
                <OverlayTrigger placement="top" overlay={addProjectTooltip}>
                  <Image
                    className="d-inline mx-2"
                    src={images.infoIcon}
                    alt="info"
                    width={24}
                    height={24}
                  />
                </OverlayTrigger>
              </div>
            </div>

            {/* Active Projects Card */}
            <div className="px-2 bg-light mt-4 mx-4 dashCard d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="mx-1"
                  src={images.activeProjects}
                  alt={t('dashboard:activeProjects.altText')}
                  width={24}
                />
                <p className="mx-2 my-1 cardText">{t('dashboard:activeProjects.title')}</p>
              </div>
              <div className="mt-2">
                <Link to="/activeproject" className="text-decoration-none">
                  <Button variant="none" className="d-inline mx-2 dashButton">
                    {t('dashboard:activeProjects.buttonText')}
                  </Button>
                </Link>
                <OverlayTrigger placement="top" overlay={ActiveProjectTooltip}>
                  <Image
                    className="d-inline mx-2"
                    src={images.infoIcon}
                    alt="info"
                    width={24}
                    height={24}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center flex-column align-items-center flex-lg-row my-2">
            {/* Archive Card */}
            <div className="px-2 bg-light mt-4 mx-4 dashCard d-flex dashCard align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="mx-1"
                  src={images.ArchiveIcon}
                  alt={t('dashboard:archive.altText')}
                  width={24}
                />
                <p className="mx-2 my-1 cardText">{t('dashboard:archive.title')}</p>
              </div>
              <div className="mt-2">
                <Link to="/archievedProject" className="text-decoration-none">
                  <Button variant="none" className="d-inline mx-2 dashButton">
                    {t('dashboard:archive.buttonText')}
                  </Button>
                </Link>
                <OverlayTrigger placement="top" overlay={ArchiveTooltip}>
                  <Image
                    className="d-inline mx-2"
                    src={images.infoIcon}
                    alt="info"
                    width={24}
                    height={24}
                  />
                </OverlayTrigger>
              </div>
            </div>

            {/* Trash Card */}
            <div className="px-2 bg-light mt-4 mx-4 dashCard d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="mx-1"
                  src={images.DeleteIcon}
                  alt={t('dashboard:trash.altText')}
                  width={24}
                />
                <p className="mx-2 my-1 cardText">{t('dashboard:trash.title')}</p>
              </div>
              <div className="mt-2">
                <Link to="/trash" className="text-decoration-none">
                  <Button variant="none" className="d-inline mx-2 dashButton">
                    {t('dashboard:trash.buttonText')}
                  </Button>
                </Link>
                <OverlayTrigger placement="top" overlay={trashTooltip}>
                  <Image
                    className="mx-2 d-inline"
                    src={images.infoIcon}
                    alt="info"
                    width={24}
                    height={24}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column flex-lg-row mb-5 pb-5">
            {/* Reports Card */}
            <div className="px-2 bg-light mt-4 mx-4 d-flex align-items-center dashCard justify-content-between">
              <div className="d-flex align-items-center">
                <Image 
                  className="mx-2" 
                  src={images.pdfIcon} 
                  alt={t('dashboard:reports.altText')} 
                  width={24} 
                />
                <p className="mx-2 my-1 cardText">{t('dashboard:reports.title')}</p>
              </div>
              <div className="mt-2">
                <Link to="/myreports" className="text-decoration-none">
                  <Button variant="none" className="d-inline mx-2 dashButton">
                    {t('dashboard:reports.buttonText')}
                  </Button>
                </Link>
                <OverlayTrigger placement="top" overlay={ReportTooltip}>
                  <Image
                    className="d-inline mx-2"
                    src={images.infoIcon}
                    alt="info"
                    width={24}
                    height={24}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Dashboard;
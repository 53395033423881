/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/03/2025 - 17:04:18
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/03/2025
    * - Author          : 
    * - Modification    : 
**/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { I18N_CONFIG } from './config';


import enCommon from './en_translation/common.json';
import enDashboard from './en_translation/dashboard.json';
import ennewProject from './en_translation/newProject.json';
import enactiveProjects from './en_translation/activeProjects.json';
import enactiveCards from './en_translation/activeCards.json';
import enarchiveProjects from './en_translation/archiveProjects.json';
import enarchiveCards from './en_translation/archiveCards.json';
import entrashPage from './en_translation/trashPage.json';
import entrashCards from './en_translation/trashCards.json';
import enmyReports from './en_translation/myReports.json';
import enreportCard from './en_translation/reportCard.json';
import enmapPage from './en_translation/mapPage.json';
import entiltedPvArea from './en_translation/tiltedPvArea.json';
import endetectionPvArea from './en_translation/detectionPvArea.json';
import enverticalPvArea from './en_translation/verticalPvArea.json';
import enunpaidProject from './en_translation/unpaidProject.json';
import enprojectOverview from './en_translation/projectOverview.json';
import enfullReport from './en_translation/fullReport.json';

////////German

import deCommon from './de_translation/common.json';
import deDashboard from './de_translation/dashboard.json';
import denewProject from './de_translation/newProject.json';
import deactiveProject from './de_translation/activeProjects.json';
import deactiveCards from './de_translation/activeCards.json';
import dearchiveProjects from './de_translation/archiveProjects.json';
import dearchiveCards from './de_translation/archiveCards.json';
import detrashPage from './de_translation/trashPage.json';
import detrashCards from './de_translation/trashCards.json';
import demyReports from './de_translation/myReports.json';
import dereportCard from './de_translation/reportCard.json';
import demapPage from './de_translation/mapPage.json';
import detiltedPvArea from './de_translation/tiltedPvArea.json';
import dedetectionPvArea from './de_translation/detectionPvArea.json';
import deverticalPvArea from './de_translation/verticalPvArea.json';
import deunpaidProject from './de_translation/unpaidProject.json';
import deprojectOverview from './de_translation/projectOverview.json';
import defullReport from './de_translation/fullReport.json';

// Resources object with preloaded translations
const resources = {
  en: {
    common: enCommon,
    dashboard: enDashboard,
    newProject:ennewProject,
    activeProjects:enactiveProjects,
    activeCards:enactiveCards,
    archiveProjects:enarchiveProjects,
    archiveCards:enarchiveCards,
    detrashPage:entrashPage,
    trashCards:entrashCards,
    myReports:enmyReports,
    reportCard:enreportCard,
    mapPage: enmapPage,
    tiltedPvArea: entiltedPvArea,
    detectionPvArea: endetectionPvArea,
    verticalPvArea: enverticalPvArea,
    unpaidProject: enunpaidProject,
    projectOverview:enprojectOverview,
    fullReport: enfullReport,
  },
  de: {
    common: deCommon,
    dashboard: deDashboard,
    newProject:denewProject,
    activeProjects:deactiveProject,
    activeCards:deactiveCards,
    archiveProjects:dearchiveProjects,
    archiveCards:dearchiveCards,
    trashPage:detrashPage,
    trashCards:detrashCards,
    myReports:demyReports,
    reportCard:dereportCard,
    mapPage: demapPage,
    tiltedPvArea: detiltedPvArea,
    detectionPvArea: dedetectionPvArea,
    verticalPvArea: deverticalPvArea,
    unpaidProject: deunpaidProject,
    projectOverview:deprojectOverview,
    fullReport: defullReport,
  }
};

i18n
  // Load browser language detector
  .use(LanguageDetector)
  // Pass i18n instance to react-i18next
  .use(initReactI18next)
  // Initialize i18next
  .init({
    // Preloaded resources instead of HTTP backend
    resources,
    
    // Default language
    fallbackLng: I18N_CONFIG.defaultLanguage,
    supportedLngs: I18N_CONFIG.supportedLanguages.map(lang => lang.code),
    
    // Define default namespace
    defaultNS: 'common',
    
    // Cache translations for better performance
    load: 'currentOnly',
    
    // Debug mode (disable in production)
    debug: process.env.NODE_ENV === 'development',
    
    interpolation: {
      // React already protects from XSS
      escapeValue: false,
    },
    
    // React-specific options
    react: {
      useSuspense: true,
    },
  });

// Function to change language
export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  
  // Update HTML attributes for RTL support
  const isRTL = I18N_CONFIG.supportedLanguages.find(lang => 
    lang.code === language
  )?.rtl || false;
  
  document.documentElement.lang = language;
  document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
  
  // Save language preference
  localStorage.setItem('i18nextLng', language);
};

export default i18n;